import { Pipe, PipeTransform } from '@angular/core';
import { ORDER_STATUS, ROLE } from '../../models/enums';

@Pipe({
  name: 'localize',
})
export class LocalizePipe implements PipeTransform {
  transform(value: string, type: 'role' | 'order_status'): string {
    if (type === 'role') {
      switch (value) {
        case ROLE.WAITER:
          return 'Ofisiant';
        case ROLE.CASHIER:
          return 'Kassir';
        case ROLE.ADMIN:
          return 'Admin';
        default:
          return 'Super Admin';
      }
    } else if (type === 'order_status') {
      switch (value.toLocaleLowerCase()) {
        case ORDER_STATUS.STARTED.toLocaleLowerCase():
          return 'Faol';
        case ORDER_STATUS.CLOSED.toLocaleLowerCase():
          return "To'langan";
        case ORDER_STATUS.CANCELLED.toLocaleLowerCase():
          return 'Bekor qilingan';
        case 'cash':
          return 'Naqd pul';
        case 'card':
          return 'Karta orqali';
      }
    }
    return value;
  }
}
