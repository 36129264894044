import { createAction, props } from '@ngrx/store';
import { Order, OrderFilter } from '../../../models/order.model';
import { PaginationResponse } from '../../../core/services/BaseApiCall';

export const loadOrders = createAction(
  '[History Page] Load orders',
  props<{
    filter: OrderFilter;
  }>()
);

export const changePagination = createAction(
  '[History Page] Load changed pagination config',
  props<{
    page: number;
    size: number;
  }>()
);

export const nextInfiniteScrollPage = createAction(
  '[History Page] Load next page with scroll'
);

export const nextInfiniteScrollPageLoadedSuccess = createAction(
  '[API Call] Load next page with scroll successfully',
  props<{ result: PaginationResponse<Order[]> }>()
);

export const paginateOrdersLoadedSuccessfully = createAction(
  '[API Call] Next page orders fetched successfully',
  props<{ result: PaginationResponse<Order[]> }>()
);

export const ordersLoadedSuccessfully = createAction(
  '[API Call] History Order fetched successfully',
  props<{ result: PaginationResponse<Order[]>; filter: OrderFilter }>()
);

export const orderLoadFailed = createAction(
  '[API Call] Order fetch failed',
  props<{ error: string }>()
);
