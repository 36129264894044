import { Injectable } from '@angular/core';
import { BaseApiCall } from '../core/services/BaseApiCall';
import { ComponentCreate, ComponentResponse } from '../models/component.model';

@Injectable({
  providedIn: 'root',
})
export class ComponentClient extends BaseApiCall<
  ComponentResponse,
  ComponentCreate
> {
  constructor() {
    super('components');
  }
}
