import { Action, combineReducers, createFeatureSelector } from '@ngrx/store';
import * as CoreReducer from './core/core.reducer';
import * as CategoryReducer from './category/category.reducer';
import * as OrdersReducer from './orders/orders.reducer';
import * as TablesReducer from './tables/tables.reducer';
import * as UsersReducer from './users/users.reducer';
import * as HistoryReducer from './history/history.reducer';
import * as ComponentReducer from './warehouse/components.reducer';
import { CoreAction } from '../actions/index.action';

export const appFeatureKey = 'app';

export interface AppState {
  [CoreReducer.featureKey]: CoreReducer.State;
  [CategoryReducer.featureKey]: CategoryReducer.State;
  [OrdersReducer.featureKey]: OrdersReducer.State;
  [TablesReducer.featureKey]: TablesReducer.State;
  [UsersReducer.featureKey]: UsersReducer.State;
  [HistoryReducer.featureKey]: HistoryReducer.State;
  [ComponentReducer.featureKey]: ComponentReducer.State;
}

export function reducers(state: AppState | undefined, action: Action) {
  if (action.type === CoreAction.logout.type) {
    state = undefined;
  }
  return combineReducers({
    [CoreReducer.featureKey]: CoreReducer.reducer,
    [CategoryReducer.featureKey]: CategoryReducer.reducer,
    [OrdersReducer.featureKey]: OrdersReducer.reducer,
    [TablesReducer.featureKey]: TablesReducer.reducer,
    [UsersReducer.featureKey]: UsersReducer.reducer,
    [HistoryReducer.featureKey]: HistoryReducer.reducer,
    [ComponentReducer.featureKey]: ComponentReducer.reducer,
  })(state, action);
}

export const selectAppState = createFeatureSelector<AppState>(appFeatureKey);
