import { CanActivateFn, Router, CanActivateChildFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.isTokenExpired()) {
    return true;
  }
  return router.navigate(['auth/login']);
};

export const authGuardForChild: CanActivateChildFn = (childRoute, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.isTokenExpired()) {
    return true;
  }
  return router.navigate(['auth/login']);
};
