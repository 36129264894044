<div
  [ngClass]="{
    'right-0': placement === 'right' && elementPosition === 'absolute',
    'left-0': placement === 'left' && elementPosition === 'absolute'
  }"
  class="{{
    elementPosition
  }} bg-white z-10 shadow-lg border rounded-xl translate-y-1 w-max min-w-[100px]">
  <div class="flex flex-col divide-y">
    <ng-container *ngFor="let option of options; let idx = index">
      <div
        (click)="optionClick.emit(option.eventName)"
        [ngClass]="{
          'text-custom-gray-500': option.type === 'primary',
          'text-red-400': option.type === 'danger',
          'rounded-t-xl': idx === 0,
          'rounded-b-xl': idx === options.length - 1
        }"
        class="flex items-center hover:bg-custom-gray-300 text-xs p-3">
        <fa-icon *ngIf="option.icon" [icon]="Icons[option.icon]"></fa-icon>
        <span class="ml-3">{{ option.title }}</span>
      </div>
    </ng-container>
  </div>
</div>
