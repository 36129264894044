import * as CoreAction from './core/core.action';
import * as CategoryAction from './category/category.action';
import * as OrdersAction from './orders/orders.action';
import * as TableAction from './tables/tables.action';
import * as UsersAction from './users/users.action';
import * as HistoryAction from './history/history.action';
import * as WarehouseAction from './warehouse/warehouse.action';

export {
  CoreAction,
  CategoryAction,
  OrdersAction,
  TableAction,
  UsersAction,
  HistoryAction,
  WarehouseAction,
};
