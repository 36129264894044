<div class="relative w-full flex">
  <div class="min-w-max w-52 bg-white rounded-xl mr-7 p-6">
    <div class="flex flex-col">
      <div
        routerLink="personal-info"
        routerLinkActive="active-link"
        class="flex items-center text-base font-normal text-custom-gray-500 py-2.5 px-6 mb-6 cursor-pointer">
        <fa-icon [icon]="Icons.userGear" class="mr-3"></fa-icon>
        <span>Shaxsiy ma'lumot</span>
      </div>
      <div
        routerLink="change-password"
        routerLinkActive="active-link"
        class="flex items-center text-base font-normal text-custom-gray-500 py-2.5 px-6 mb-6 cursor-pointer">
        <fa-icon [icon]="Icons.lock" class="mr-3"></fa-icon>
        <span>Parol o'zgartirish</span>
      </div>
      <div
        (click)="handleLogout()"
        class="flex items-center text-base font-normal text-custom-gray-500 py-2.5 px-6 cursor-pointer">
        <fa-icon [icon]="Icons.rightFromBracket" class="mr-3"></fa-icon>
        <span>Chiqish</span>
      </div>
    </div>
  </div>
  <div class="w-full relative bg-white rounded-xl">
    <router-outlet></router-outlet>
  </div>
</div>
