import {
  Component,
  HostListener,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { AppNavigationItem, ToasterOptions } from '../../../models/types';
import { AuthService } from '../../services/auth/auth.service';
import { ROLE } from '../../../models/enums';
import { icons } from '../../../models/fontawesome';
import { CommonService } from '../../../_services/common.service';
import { filter, map, Observable } from 'rxjs';
import { ModalService } from '../../../_services/modal.service';
import { Store } from '@ngrx/store';
import { CategoryAction } from '../../../store/actions/index.action';
import { User } from '../../../models/user.model';
import { selectAuthorizedUser } from '../../../store/selectors/core/core.selector';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent {
  Icons = icons;

  get windowWidth(): Observable<number> {
    return this.commonService.windowWidth$;
  }

  get authorizedUser(): Observable<User | undefined> {
    return this.store$.select(selectAuthorizedUser);
  }

  get toaster(): Observable<ToasterOptions | null> {
    return this.commonService.toaster$;
  }

  constructor(
    private navigationService: NavigationService,
    private commonService: CommonService,
    private modalService: ModalService,
    private vcr: ViewContainerRef,
    private store$: Store
  ) {
    this.modalService.appContainerRef = vcr;
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.commonService.windowWidth$.next(window.innerWidth);
  }

  getNavigationList(user?: User): AppNavigationItem[] {
    if (user) {
      switch (user.role) {
        case ROLE.SUPER_ADMIN:
          return this.navigationService.navigations.SUPER_ADMIN;
        case ROLE.ADMIN:
          return this.navigationService.navigations.ADMIN;
        case ROLE.CASHIER:
          return this.navigationService.navigations.CASHIER;
        case ROLE.WAITER:
          return this.navigationService.navigations.WAITER;
      }
    }
    return [];
  }
}
