<div
  [ngClass]="{
    'left-1 top-4': placement === 'top-left',
    'left-2 bottom-4': placement === 'bottom-left',
    'right-1 top-4': placement === 'top-right',
    'right-2 bottom-4': placement === 'bottom-right'
  }"
  class="absolute left-1 top-4 z-50 bg-white shadow-2xl rounded-2xl">
  <div
    class="w-72 md:w-96 md:h-20 flex items-start justify-start p-4 overflow-hidden">
    <div class="shrink-0">
      <ng-container *ngIf="status === 'success'">
        <fa-icon
          [icon]="Icons.circleCheck"
          class="text-green-500 text-base"></fa-icon>
      </ng-container>
      <ng-container *ngIf="status === 'error'">
        <fa-icon
          [icon]="Icons.circleXmark"
          class="text-red-500 text-base"></fa-icon>
      </ng-container>
      <ng-container *ngIf="status === 'info'">
        <fa-icon
          [icon]="Icons.circleInfo"
          class="text-sky-500 text-base"></fa-icon>
      </ng-container>
      <ng-container *ngIf="status === 'warning'">
        <fa-icon
          [icon]="Icons.circleExclamation"
          class="text-yellow-500 text-base"></fa-icon>
      </ng-container>
    </div>
    <div class="flex-1 pt-0.5 ml-3 w-0">
      <p class="text-sm font-medium text-gray-900">{{ title }}</p>
      <p class="text-sm mt-1 text-gray-500">{{ description }}</p>
    </div>
    <div class="shrink-0 flex ml-4">
      <button class="bg-white text-gray-400" (click)="handleClose()">
        <fa-icon [icon]="Icons.xmark"></fa-icon>
      </button>
    </div>
  </div>
</div>
