import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CoreAction, TableAction } from '../../actions/index.action';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TableClient } from '../../../_clients/table.client';

@Injectable()
export class TablesEffect {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private tableService: TableClient
  ) {}

  loadTables$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TableAction.loadTables,
        TableAction.loadTablesFromHistoryPage,
        TableAction.loadTablesFromLayout
      ),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      switchMap(action =>
        this.tableService.getAll().pipe(
          map(response => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return TableAction.tablesLoadedSuccessfully({
              result: response,
            });
          }),
          catchError((err: HttpErrorResponse) => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return of(
              TableAction.tablesLoadFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });

  createTable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TableAction.createTable),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      switchMap(action =>
        this.tableService.create(action.payload).pipe(
          map(response => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return TableAction.tableCreatedSuccessfully({
              result: response,
            });
          }),
          catchError((err: HttpErrorResponse) => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return of(
              TableAction.tablesLoadFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });

  updateTable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TableAction.updateTable),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      switchMap(action =>
        this.tableService.update(action.id, action.payload).pipe(
          map(response => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return TableAction.tableUpdatedSuccessfully({
              id: action.id,
              result: response,
            });
          }),
          catchError((err: HttpErrorResponse) => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return of(
              TableAction.tableUpdateFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });

  deleteTable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TableAction.deleteTable),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      switchMap(action =>
        this.tableService.delete(action.id).pipe(
          map(response => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return TableAction.tableDeletedSuccessfully({
              id: action.id,
            });
          }),
          catchError((err: HttpErrorResponse) => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return of(
              TableAction.tableDeleteFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });
}
