<app-mobile-layout *ngIf="windowWidth | async | checkWidth: 'less' : 'tablet'">
</app-mobile-layout>
<app-desktop-layout
  *ngIf="windowWidth | async | checkWidth: 'greater' : 'tablet'">
</app-desktop-layout>
<ng-container *ngIf="(toaster | async) !== null">
  <app-toaster
    [ngClass]="{
      'left-1 top-4': (toaster | async)!.placement === 'top-left',
      'left-2 bottom-4': (toaster | async)!.placement === 'bottom-left',
      'right-1 top-4': (toaster | async)!.placement === 'top-right',
      'right-2 bottom-4': (toaster | async)!.placement === 'bottom-right'
    }"
    class="absolute"
    [title]="(toaster | async)!.title"
    [description]="(toaster | async)!.description"
    [status]="(toaster | async)!.status"
    [placement]="(toaster | async)!.placement"
    [duration]="(toaster | async)!.duration"></app-toaster>
</ng-container>
