<ng-container *ngIf="windowWidth$ | async | checkWidth: 'greater' : 'tablet'">
  <div class="relative w-full p-6">
    <div class="flex flex-col">
      <span class="text-2xl font-semibold mb-12">Shaxsiy ma'lumot</span>
      <div class="flex flex-col mb-12" [formGroup]="userForm">
        <app-input
          className="mb-8"
          [form]="userForm"
          control="name"
          label="Ism"
          placeholder="Ism kiriting..."
          formStyleType="solid"
          inputType="text"></app-input>
        <app-input
          [form]="userForm"
          control="username"
          label="Login"
          placeholder="login kiriting..."
          formStyleType="solid"
          inputType="text"
          [suffixIcon]="usernameLoading ? 'spinner' : undefined"
          [suffixIconAnimation]="'spin'"
          [errorText]="usernameErrorText"></app-input>
      </div>
      <div
        class="flex items-center"
        [ngClass]="{
          'justify-between': userForm.dirty,
          'justify-end': !userForm.dirty
        }">
        <button
          *ngIf="userForm.dirty"
          (click)="handleRevertChanges()"
          class="w-2/5 py-3 rounded-xl border border-primary-dark text-primary-dark text-base font-semibold">
          Bekor qilish
        </button>
        <button
          (click)="handleSubmit()"
          [disabled]="userForm.invalid"
          class="w-2/5 py-3 rounded-xl bg-primary-dark text-white text-base font-semibold">
          <ng-container *ngIf="!formLoading; else loadingTemplate">
            Saqlash
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="windowWidth$ | async | checkWidth: 'less' : 'tablet'">
  <div class="relative h-full bg-white w-full">
    <div class="w-full h-full overflow-hidden">
      <div class="w-full h-full flex flex-col justify-between">
        <div class="w-full flex flex-col p-3">
          <app-input
            className="mb-8"
            [form]="userForm"
            control="name"
            label="Ism"
            placeholder="Ism kiriting..."
            formStyleType="solid"
            inputType="text"></app-input>
          <app-input
            [form]="userForm"
            control="username"
            label="Login"
            placeholder="login kiriting..."
            formStyleType="solid"
            inputType="text"
            [suffixIcon]="usernameLoading ? 'spinner' : undefined"
            [suffixIconAnimation]="'spin'"
            [errorText]="usernameErrorText"></app-input>
        </div>
        <div
          [ngClass]="{
            'justify-center': userForm.dirty,
            'justify-end': !userForm.dirty
          }"
          class="w-full h-[60px] flex items-center gap-x-5 px-6">
          <button
            *ngIf="userForm.dirty"
            (click)="handleRevertChanges()"
            class="w-full py-3 px-5 rounded-xl border border-primary-dark text-primary-dark text-sm font-semibold">
            Bekor qilish
          </button>
          <button
            (click)="handleSubmit(true)"
            [disabled]="userForm.invalid"
            class="w-full py-3 px-5 rounded-xl bg-primary-dark text-white text-sm font-semibold">
            <ng-container *ngIf="!formLoading; else loadingTemplate">
              Saqlash
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #headerLeftSideTemplate>
  <fa-icon
    class="h-full text-custom-gray-500 text-lg flex items-center pl-4"
    [icon]="Icons.arrowLeft"
    (click)="handleBack()"></fa-icon>
</ng-template>

<ng-template #headerTitleTemplate>
  <div class="font-medium text-base tracking-widest text-black">
    Shaxsiy ma'lumot
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="flex justify-center items-center">
    <fa-icon [icon]="Icons.spinner" animation="spin" class="mr-3"></fa-icon>
    yuklanmoqda
  </div>
</ng-template>
