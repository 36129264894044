import {
  AfterViewInit,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '../../../_services/toaster.service';
import { icons } from '../../../models/fontawesome';
import { CommonService } from '../../../_services/common.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { UserClient } from '../../../_clients/user.client';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements AfterViewInit, OnDestroy {
  passwordForm: FormGroup;
  loading = false;
  Icons = icons;

  @ViewChild('headerLeftSideTemplate') headerLeftSide: TemplateRef<any> | null =
    null;
  @ViewChild('headerTitleTemplate') headerTitle: TemplateRef<any> | null = null;
  get windowWidth$(): Observable<number> {
    return this.commonService.windowWidth$;
  }
  constructor(
    private fb: FormBuilder,
    private userService: UserClient,
    private toaster: ToasterService,
    private commonService: CommonService,
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.passwordForm = fb.group({
      oldPassword: new FormControl(null, Validators.required),
      newPassword: new FormControl(null, Validators.required),
    });
  }

  ngAfterViewInit(): void {
    if (this.headerLeftSide && this.headerTitle) {
      Promise.resolve().then(() => {
        this.navigationService.appHeaderLeftSideTemplate$.next(
          this.headerLeftSide
        );
        this.navigationService.appHeaderMiddleTemplate$.next(this.headerTitle);
      });
    }
  }

  ngOnDestroy(): void {
    this.navigationService.appHeaderLeftSideTemplate$.next(null);
    this.navigationService.appHeaderMiddleTemplate$.next(null);
  }

  handleBack(): void {
    this.router.navigate(['profile']);
  }

  handleSubmit(isMobile = false): void {
    if (this.passwordForm.valid) {
      this.loading = true;
      this.userService
        .changePassword(this.passwordForm.getRawValue())
        .subscribe({
          next: data => {
            this.loading = false;
            this.toaster.success({
              title: 'Muvaffaqiyat',
              description: data.message,
            });
            this.passwordForm.reset({
              oldPassword: null,
              newPassword: null,
            });
            if (isMobile) {
              this.handleBack();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.loading = false;
            this.toaster.error({
              title: 'Xatolik',
              description: err.error.message,
            });
          },
        });
    }
  }
}
