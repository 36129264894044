import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { ToasterOptions } from '../models/types';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private commonService: CommonService) {}

  success({
    title = 'Muvaffaqiyatli',
    description = '',
    placement = 'top-right',
    status = 'success',
    duration = 3000,
  }: Partial<ToasterOptions>): void {
    this.commonService.toaster$.next({
      title,
      description,
      placement,
      status,
      duration,
    });
  }

  error({
    title = 'Xatolik!',
    description = '',
    placement = 'top-right',
    status = 'error',
    duration = 3000,
  }: Partial<ToasterOptions>): void {
    this.commonService.toaster$.next({
      title,
      description,
      placement,
      status,
      duration,
    });
  }

  info({
    title = '',
    description = '',
    placement = 'top-right',
    status = 'info',
    duration = 3000,
  }: Partial<ToasterOptions>): void {
    this.commonService.toaster$.next({
      title,
      description,
      placement,
      status,
      duration,
    });
  }

  warning({
    title = '',
    description = '',
    placement = 'top-right',
    status = 'warning',
    duration = 3000,
  }: Partial<ToasterOptions>): void {
    this.commonService.toaster$.next({
      title,
      description,
      placement,
      status,
      duration,
    });
  }
}
