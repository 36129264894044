<!-- Calendar -->
<div class="space-y-0.5 {{ classNames }} ">
  <!-- Months -->
  <div class="grid grid-cols-4 items-center gap-x-3 mx-1.5 pb-3">
    <!-- Prev Button -->
    <div class="col-span-1">
      <button
        (click)="prevMonth()"
        type="button"
        class="w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none">
        <svg
          class="flex-shrink-0 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path d="m15 18-6-6 6-6" />
        </svg>
      </button>
    </div>
    <!-- End Prev Button -->

    <!-- Month / Year -->
    <div class="col-span-2 flex justify-center items-center gap-x-1">
      <div class="relative cursor-pointer p-0 text-center">
        <span (click)="showMonthOption = !showMonthOption">
          {{ getSelectedMonthName() }}
        </span>
        <div
          *ngIf="showMonthOption"
          class="absolute top-full -translate-x-1/2 left-1/2 flex flex-col w-24 max-h-[150px] overflow-y-auto bg-white shadow-lg border rounded-lg">
          <ng-container *ngFor="let value of monthData">
            <div
              (click)="handleChangeMonth(value[0])"
              class="flex justify-between items-center text-xs px-3 py-2 hover:bg-gray-200">
              <span>
                {{
                  enableShortNames ? value[1].short_name : value[1].full_name
                }}
              </span>
              <fa-icon
                *ngIf="selectedMonth === value[0]"
                [icon]="Icons.check"></fa-icon>
            </div>
          </ng-container>
        </div>
      </div>

      <span>
        {{ calendarDate.getFullYear() }}
      </span>
    </div>
    <!-- End Month / Year -->

    <!-- Next Button -->
    <div class="col-span-1 flex justify-end">
      <button
        (click)="nextMonth()"
        type="button"
        class="w-8 h-8 flex justify-center items-center text-gray-800 hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:pointer-events-none">
        <svg
          class="flex-shrink-0 w-4 h-4"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round">
          <path d="m9 18 6-6-6-6" />
        </svg>
      </button>
    </div>
    <!-- End Next Button -->
  </div>
  <!-- Months -->

  <!-- Weeks -->
  <div class="flex pb-1.5">
    <span class="m-px w-6 md:w-10 block text-center text-sm text-gray-500">
      Mo
    </span>
    <span class="m-px w-6 md:w-10 block text-center text-sm text-gray-500">
      Tu
    </span>
    <span class="m-px w-6 md:w-10 block text-center text-sm text-gray-500">
      We
    </span>
    <span class="m-px w-6 md:w-10 block text-center text-sm text-gray-500">
      Th
    </span>
    <span class="m-px w-6 md:w-10 block text-center text-sm text-gray-500">
      Fr
    </span>
    <span class="m-px w-6 md:w-10 block text-center text-sm text-gray-500">
      Sa
    </span>
    <span class="m-px w-6 md:w-10 block text-center text-sm text-gray-500">
      Su
    </span>
  </div>
  <!-- Weeks -->

  <!-- Days -->
  <div class="grid grid-cols-7">
    <ng-container *ngFor="let day of getDaysInMonth()">
      <div>
        <ng-container *ngIf="day; else blankDayTemplate">
          <button
            [ngClass]="{
              'bg-primary text-white':
                day.getDate() === selectedDate?.getDate() &&
                day.getMonth() === selectedDate?.getMonth(),
              'text-gray-800 hover:text-primary':
                day.getDate() !== selectedDate?.getDate() ||
                day.getMonth() !== selectedDate?.getMonth()
            }"
            (click)="handleSelectDate(day)"
            type="button"
            class="m-px w-6 md:w-10 h-6 md:h-10 flex justify-center items-center border border-transparent text-sm hover:border-primary rounded-full disabled:text-gray-300 disabled:pointer-events-none">
            {{ day.getDate() }}
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <!-- Days -->
</div>
<!-- End Calendar -->

<ng-template #blankDayTemplate>
  <button type="button" class="m-px w-6 md:w-10 h-6 md:h-10"></button>
</ng-template>
