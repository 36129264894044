import { Injectable } from '@angular/core';
import { BaseApiCall } from '../core/services/BaseApiCall';
import { Order, OrderCreate } from '../models/order.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PAYMENT_TYPE } from '../models/enums';

@Injectable({
  providedIn: 'root',
})
export class OrderClient extends BaseApiCall<Order, OrderCreate> {
  constructor(private orderHttp: HttpClient) {
    super('orders');
  }

  cancel(orderId: number): Observable<{ message: string }> {
    return this.orderHttp.post<{ message: string }>(
      `${environment.baseApiUrl}/orders/${orderId}/cancel`,
      {}
    );
  }

  complete(orderId: number): Observable<{ message: string }> {
    return this.orderHttp.post<{ message: string }>(
      `${environment.baseApiUrl}/orders/${orderId}/complete`,
      {}
    );
  }

  close(
    orderId: number,
    payload: { paymentType: PAYMENT_TYPE; discount: number }
  ): Observable<{ message: string }> {
    return this.orderHttp.post<{ message: string }>(
      `${environment.baseApiUrl}/orders/${orderId}/close`,
      payload
    );
  }
}
