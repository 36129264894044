export enum MONTH {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export type Month = {
  full_name: string;
  short_name: string;
};

export const uzLatinMonth: Record<MONTH, Month> = {
  [MONTH.JANUARY]: {
    full_name: 'Yanvar',
    short_name: 'Yan',
  },
  [MONTH.FEBRUARY]: {
    full_name: 'Fevral',
    short_name: 'Fev',
  },
  [MONTH.MARCH]: {
    full_name: 'Mart',
    short_name: 'Mart',
  },
  [MONTH.APRIL]: {
    full_name: 'Aprel',
    short_name: 'Apr',
  },
  [MONTH.MAY]: {
    full_name: 'May',
    short_name: 'May',
  },
  [MONTH.JUNE]: {
    full_name: 'Iyun',
    short_name: 'Iyun',
  },
  [MONTH.JULY]: {
    full_name: 'Iyul',
    short_name: 'Iyul',
  },
  [MONTH.AUGUST]: {
    full_name: 'Avgust',
    short_name: 'Avg',
  },
  [MONTH.SEPTEMBER]: {
    full_name: 'Sentabr',
    short_name: 'Sen',
  },
  [MONTH.OCTOBER]: {
    full_name: 'Oktabr',
    short_name: 'Okt',
  },
  [MONTH.NOVEMBER]: {
    full_name: 'Noyabr',
    short_name: 'Noy',
  },
  [MONTH.DECEMBER]: {
    full_name: 'Dekabr',
    short_name: 'Dek',
  },
};
