import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { icons } from '../../../models/fontawesome';
import { debounceTime, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from '../../../_services/toaster.service';
import { CommonService } from '../../../_services/common.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { UserClient } from '../../../_clients/user.client';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  userForm: FormGroup;
  formLoading = false;
  Icons = icons;
  usernameErrorText: string | undefined;
  usernameLoading = false;

  @ViewChild('headerLeftSideTemplate') headerLeftSide: TemplateRef<any> | null =
    null;
  @ViewChild('headerTitleTemplate') headerTitle: TemplateRef<any> | null = null;
  get windowWidth$(): Observable<number> {
    return this.commonService.windowWidth$;
  }
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private userService: UserClient,
    private toaster: ToasterService,
    private commonService: CommonService,
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.userForm = fb.group({
      name: new FormControl(authService.user?.name),
      username: new FormControl(
        authService.user?.username,
        Validators.required
      ),
    });
  }

  ngOnInit(): void {
    this.userForm
      .get('username')
      ?.valueChanges?.pipe(debounceTime(1000))
      .subscribe(value => {
        if (value !== this.authService.user?.username) {
          this.usernameLoading = true;
          this.userService.usernameAvailability(value).subscribe(isUnique => {
            this.usernameLoading = false;
            this.usernameErrorText = isUnique
              ? undefined
              : 'Bu login band! boshqasini kiriting';
          });
        } else {
          this.usernameErrorText = undefined;
        }
      });
  }

  handleRevertChanges(): void {
    this.userForm.reset({
      name: this.authService.user?.name,
      username: this.authService.user?.username,
    });
  }

  handleSubmit(isMobile = false): void {
    if (this.userForm.valid && this.authService.user?.id) {
      this.formLoading = true;
      this.userService
        .update(this.authService.user?.id, {
          name: this.userForm.get('name')?.value ?? null,
          username: this.userForm.get('username')?.value,
          role: this.authService.user.role,
        })
        .subscribe({
          next: data => {
            this.formLoading = false;
            this.authService.user = data;
            this.toaster.success({
              title: 'Muvaffaqiyat',
              description: "Ma'lumotlar saqlandi",
            });
            if (isMobile) {
              this.handleBack();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.formLoading = false;
            this.toaster.error({
              title: 'Xatolik',
              description: err.error.message,
            });
          },
        });
    }
  }

  ngAfterViewInit(): void {
    if (this.headerLeftSide && this.headerTitle) {
      Promise.resolve().then(() => {
        this.navigationService.appHeaderLeftSideTemplate$.next(
          this.headerLeftSide
        );
        this.navigationService.appHeaderMiddleTemplate$.next(this.headerTitle);
      });
    }
  }

  ngOnDestroy(): void {
    this.navigationService.appHeaderLeftSideTemplate$.next(null);
    this.navigationService.appHeaderMiddleTemplate$.next(null);
  }

  handleBack(): void {
    this.router.navigate(['profile']);
  }
}
