import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, SelectControlValueAccessor } from '@angular/forms';
import { DropdownItem } from '../../../../models/types';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends SelectControlValueAccessor {
  @Input() form!: FormGroup;
  @Input() control!: string;
  @Input() className = '';
  @Input() formStyleType: 'solid' | 'simple' = 'simple';
  @Input() label: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() items: DropdownItem[] = [];
  @Input() errorText: string | undefined;

  @Output() optionChange = new EventEmitter();
}
