import { Injectable, TemplateRef } from '@angular/core';
import { AppNavigationItem } from '../../../models/types';
import { ROLE } from 'src/app/models/enums';
import { BehaviorSubject } from 'rxjs';
import { appSidebarNavigations } from '../../../models/constants';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  navigations: Record<ROLE, AppNavigationItem[]> = {
    SUPER_ADMIN: appSidebarNavigations,
    ADMIN: appSidebarNavigations,
    WAITER: appSidebarNavigations.filter(route =>
      ['menu', 'orders'].includes(route.slug)
    ),
    CASHIER: appSidebarNavigations.filter(route =>
      ['menu', 'orders'].includes(route.slug)
    ),
  };
  appHeaderLeftSideTemplate$: BehaviorSubject<TemplateRef<any> | null> =
    new BehaviorSubject<TemplateRef<any> | null>(null);
  appHeaderRightSideTemplate$: BehaviorSubject<TemplateRef<any> | null> =
    new BehaviorSubject<TemplateRef<any> | null>(null);
  appHeaderMiddleTemplate$: BehaviorSubject<TemplateRef<any> | null> =
    new BehaviorSubject<TemplateRef<any> | null>(null);
}
