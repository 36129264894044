<button
  [ngClass]="{
    'opacity-50 cursor-not-allowed': buttonDisabled || buttonLoading
  }"
  class="rounded-md focus:outline-none  {{ buttonSizeClass }} {{
    buttonTypeClass
  }}"
  [disabled]="buttonDisabled || buttonLoading"
  (click)="handleClick()">
  <fa-icon
    *ngIf="buttonLoading"
    [icon]="Icons.spinner"
    class="animate-spin h-5 w-5 mr-1"></fa-icon>
  <ng-content></ng-content>
</button>
