import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { icons } from '../../../../models/fontawesome';
import * as moment from 'moment/moment';
import * as daterangepicker from 'daterangepicker';

declare let $: any;

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements AfterViewInit {
  readonly Icons = icons;
  showCalendar = false;
  selectedDate: Date = new Date();

  @Input() initialDate: unknown;
  @Input() calendarNumber!: number;
  @Input() formStyleType: 'solid' | 'simple' = 'simple';
  @Input() label: string | undefined;
  @Input() placeholder = 'Sana tanlang';
  @Input() className = '';

  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  ngAfterViewInit(): void {
    moment().locale('uz-latn');
    $(`#input-` + this.calendarNumber)
      .daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        ranges: {
          Bugun: [moment(), moment()],
          Kecha: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        },
        showCustomRangeLabel: true,
        autoApply: true,
        opens: 'center',
        locale: {
          format: 'LL',
          separator: ' - ',
          applyLabel: 'Tanlash',
          cancelLabel: 'Bekor',
          fromLabel: 'Dan',
          toLabel: 'Gacha',
          customRangeLabel: 'Boshqa sana',
          weekLabel: 'H',
          daysOfWeek: ['Ya', 'Du', 'Se', 'Cho', 'Pa', 'Ju', 'Sha'],
          monthNames: [
            'Yanvar',
            'Fevral',
            'Mart',
            'Aprel',
            'May',
            'Iyun',
            'Iyul',
            'Avgust',
            'Sentyabr',
            'Oktyabr',
            'Noyabr',
            'Dekabr',
          ],
          firstDay: 1,
        },
        startDate: this.selectedDate,
        maxDate: moment().endOf('month').toDate(),
        minYear: new Date().getFullYear() - 5,
      })
      .on(
        'apply.daterangepicker',
        (ev: Event, picker: daterangepicker.DateRangePicker) => {
          this.handleDateSelect(picker.startDate.toDate());
        }
      );
  }

  handleDateSelect(date: Date): void {
    this.showCalendar = false;
    this.selectedDate = date;
    this.selectedDateChange.emit(date);
  }
}
