import { environment } from '../../../environments/environment';
import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class BaseApiCall<T, TCreate> {
  private readonly baseUrl = environment.baseApiUrl;
  private readonly requestEndpoint: string;
  private readonly http = inject(HttpClient);

  protected constructor(url: string) {
    this.requestEndpoint = `${this.baseUrl}/${url}`;
  }

  get(id: number): Observable<T> {
    return this.http.get<T>(`${this.requestEndpoint}/${id}`);
  }

  getAllWithPagination<F>(
    params = '',
    filter?: F
  ): Observable<PaginationResponse<T[]>> {
    if (params && !params.startsWith('?')) {
      params = '?' + params;
    }
    return this.http.post<PaginationResponse<T[]>>(
      this.requestEndpoint + '/list' + params,
      filter ?? {}
    );
  }

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.requestEndpoint);
  }

  create(payload: TCreate): Observable<T> {
    return this.http.post<T>(this.requestEndpoint, payload);
  }

  update(id: number, payload: TCreate): Observable<T> {
    return this.http.put<T>(`${this.requestEndpoint}/${id}`, payload);
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.requestEndpoint}/${id}`);
  }
}

export type PaginationResponse<T> = {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: T;
};
