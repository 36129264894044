import { createAction, props } from '@ngrx/store';
import { User } from '../../../models/user.model';

export const loadUsers = createAction('[User Page] Load users');

export const loadUsersFromHistoryPage = createAction(
  '[History Page] Load users'
);

export const loadUsersFromLayout = createAction(
  '[Layout Component] Load users'
);

export const usersLoadedSuccessfully = createAction(
  '[API Call] Users fetched successfully',
  props<{ result: User[] }>()
);

export const usersLoadFailed = createAction(
  '[API Call] Users fetch failed',
  props<{ error: string }>()
);
