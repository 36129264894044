<div class="relative">
  <ng-container *ngIf="label; else noLabel">
    <label class="block {{ className }}">
      <span class="text-gray-700">{{ label }}</span>
      <div class="w-full relative">
        <fa-icon
          [icon]="Icons.calendarDaysRegular"
          class="font-normal text-sm text-custom-gray-500 absolute left-2 top-1/2 -translate-y-1/2"></fa-icon>
        <input
          [id]="'input-' + calendarNumber"
          type="text"
          readonly
          [value]="selectedDate | date: 'dd MMMM, yyyy'"
          [ngClass]="{
            'bg-gray-100': formStyleType === 'solid',
            'border-transparent': formStyleType === 'solid',
            'border-primary': formStyleType === 'simple'
          }"
          class="mt-1 text-sm cursor-pointer pl-7 block w-full rounded-md focus:bg-white"
          [placeholder]="placeholder" />
      </div>
    </label>
  </ng-container>

  <ng-template #noLabel>
    <div class="{{ className }} flex flex-col">
      <div class="w-full relative">
        <fa-icon
          [icon]="Icons.calendarDaysRegular"
          class="font-normal text-sm text-custom-gray-500 absolute left-2 top-1/2 -translate-y-1/2"></fa-icon>
        <input
          [id]="'input-' + calendarNumber"
          readonly
          [value]="selectedDate | date: 'dd MMMM, yyyy'"
          type="text"
          [ngClass]="{
            'bg-gray-100': formStyleType === 'solid',
            'border-transparent': formStyleType === 'solid',
            'border-primary': formStyleType === 'simple'
          }"
          class="pl-7 text-sm cursor-pointer block w-full rounded-md focus:bg-white"
          [placeholder]="placeholder" />
      </div>
    </div>
  </ng-template>
</div>
