import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CommonService } from '../../../_services/common.service';
import { ModalService } from '../../../_services/modal.service';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadCategories } from '../../../store/actions/category/category.action';
import { TableAction, UsersAction } from '../../../store/actions/index.action';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: [
    './mobile-layout.component.scss',
    '../app-layout/app-layout.component.scss',
  ],
})
export class MobileLayoutComponent
  extends AppLayoutComponent
  implements AfterViewInit, OnInit
{
  drawerVisible = false;
  title = '';

  get leftSideHeaderTemplate$(): Observable<TemplateRef<any> | null> {
    return this.navigation.appHeaderLeftSideTemplate$;
  }

  get rightSideHeaderTemplate$(): Observable<TemplateRef<any> | null> {
    return this.navigation.appHeaderRightSideTemplate$;
  }

  get middleSideHeaderTemplate$(): Observable<TemplateRef<any> | null> {
    return this.navigation.appHeaderMiddleTemplate$;
  }

  constructor(
    private elementRef: ElementRef,
    private navigation: NavigationService,
    private common: CommonService,
    private modal: ModalService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private store: Store
  ) {
    super(navigation, common, modal, viewContainerRef, store);
    const url = router.url.replace('/', '');
    this.setTitle(url);
    this.store.dispatch(loadCategories());
    this.store.dispatch(TableAction.loadTablesFromLayout());
    this.store.dispatch(UsersAction.loadUsersFromLayout());
  }

  ngOnInit(): void {
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        const url = events.url.replace('/', '');
        this.setTitle(url);
      }
    });
  }

  ngAfterViewInit(): void {
    const drawer = document.getElementById('drawer');
    this.elementRef.nativeElement.addEventListener(
      'click',
      (event: MouseEvent) => {
        if (
          event.target !== drawer &&
          event.clientX > 35 &&
          event.clientY > 35
        ) {
          this.drawerVisible = false;
        }
      }
    );
  }

  private setTitle(url: string) {
    this.authorizedUser.subscribe(user => {
      if (user) {
        this.title =
          this.getNavigationList(user).find(
            navigation => navigation.slug === url
          )?.title ?? '';
      }
    });
  }
}
