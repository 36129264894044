import { Component, Input } from '@angular/core';
import { icons } from '../../../models/fontawesome';

@Component({
  selector: 'app-empty-placeholder',
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss'],
})
export class EmptyPlaceholderComponent {
  readonly Icons = icons;
  @Input() text = '';
}
