import { Component } from '@angular/core';

@Component({
  selector: 'app-advanced-select',
  templateUrl: './advanced-select.component.html',
  styleUrls: ['./advanced-select.component.scss']
})
export class AdvancedSelectComponent {

}
