import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector:
    '[appNumberFormat][formControlName],[appNumberFormat][formControl],[appNumberFormat][ngModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumberFormatDirective,
      multi: true,
    },
  ],
})
export class NumberFormatDirective implements ControlValueAccessor {
  @Input() formatNumberDisable = false;
  @Input() formatNumberType: 'number' | 'decimal' | string = 'number';
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    if (!this.formatNumberDisable) {
      const inputElement = this.el.nativeElement;
      const value = inputElement.value;
      console.log('value', value);
      // .replace(/[^\d.,]/g, '') // Remove non-digit characters except . and ,
      // .replace(/(,.*\.)|(\..*,)/g, '') // Remove if both , and . are present
      // .replace(/(\..*\.)|(,.*,)/g, (m: string) => m[0]); // Allow only one . or ,
      // if (this.formatNumberType !== 'decimal') {
      //   value = this.trimLeadingZeros(value); // Remove leading zeros
      // }
      const formattedValue = this.formatNumber(value); // Format the number
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue); // Set the input value
      this.onChange(value); // Update the form control value
    }
  }

  private trimLeadingZeros(value: string): string {
    return value.replace(/^0+/, ''); // Remove leading zeros
  }

  private formatNumber(value: string): string {
    if (!value) return ''; // Return empty string if value is empty or not provided

    // Add thousand separators
    let parts = value;
    if (this.formatNumberType === 'number') {
      parts = Number(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } else if (this.formatNumberType === 'decimal') {
      console.log(parseFloat(value));
      parts = parseFloat(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    return parts;
  }

  // Implement ControlValueAccessor interface
  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    const formattedValue = this.formatNumber(value);
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
