import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(value: number | string, local = 'ru'): string {
    let result = " so'm";
    if (typeof value === 'number') {
      result = value.toLocaleString(local) + result;
    } else {
      result = Number(value).toLocaleString(local) + result;
    }
    return result;
  }
}
