import { Component, ElementRef, Input } from '@angular/core';
import { LocalStorageService } from '../../../core/services/local-storage/local-storage.service';
import { Settings } from '../../../models/settings.model';
import { OrderTypes, PaymentTypes } from '../../../models/enums';
import { Order } from '../../../models/order.model';
import { icons } from '../../../models/fontawesome';

@Component({
  selector: 'app-cheque',
  templateUrl: './cheque.component.html',
  styleUrls: ['./cheque.component.scss'],
})
export class ChequeComponent {
  restourantName?: string;
  readonly OrderTypes = OrderTypes;
  readonly PaymentTypes = PaymentTypes;
  readonly Icons = icons;

  @Input() order?: Order;
  constructor(
    private localStorageService: LocalStorageService,
    private elementRef: ElementRef
  ) {
    const settingsData: Settings | undefined =
      this.localStorageService.get('settings');
    if (settingsData) {
      this.restourantName = settingsData.name;
    }
  }

  print(): void {
    const childElement = this.elementRef.nativeElement;
    const childHtml = childElement.outerHTML; // Get entire HTML content

    const printWindow = window.open('', ''); // Optional: Set window dimensions
    if (printWindow) {
      printWindow.document.write(childHtml);
      printWindow.document.close();
      printWindow.print();
    }
  }
}
