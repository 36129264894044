import { Component, Input, TemplateRef } from '@angular/core';
import { CommonTableColumn, TableColumn } from '../../../../models/types';
import { icons } from '../../../../models/fontawesome';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() columns!: TableColumn[];
  @Input() rows!: any[];
  @Input() rowTemplate!: TemplateRef<any>;
  @Input() shadow = true;
  @Input() appearance: 'default' | 'striped' = 'default';
  @Input() overflowX = true;

  readonly Icons = icons;

  isNameColumn(
    column: TableColumn
  ): column is { name: string } & CommonTableColumn {
    return 'name' in column;
  }

  isNameTemplateColumn(
    column: TableColumn
  ): column is { nameTemplate: TemplateRef<any> } & CommonTableColumn {
    return 'nameTemplate' in column;
  }
}
