<ng-container *ngIf="order && restourantName">
  <style>
    @media print {
      @page {
        size: 46mm;
        margin: 0;
      }
    }
  </style>
  <div
    style="
      width: 46mm;
      position: relative;
      margin: 0;
      display: flex;
      flex-direction: column;
      font-family: 'Geologica', sans-serif;
      color: #000;
      font-size: 10px;
    ">
    <div
      style="
        width: 100%;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: self-start;
        margin-top: 0;
      ">
      {{ restourantName }}
    </div>
    <div style="width: 100%; display: flex; justify-content: center">
      <h2 style="margin: 10px 0">Chek № {{ order.number }}</h2>
    </div>
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: 2px dashed;
        border-bottom: 2px dashed;
        padding: 10px 0;
      ">
      <div
        style="
          width: 100%;
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          row-gap: 2px;
        ">
        <div style="width: 100%; display: flex; flex-wrap: wrap">
          Sana:
          {{ order.createdDate | date: 'dd-MMMM, YYYY' : undefined : 'uz' }}
        </div>
        <div style="width: 100%; display: flex; flex-wrap: wrap">
          Vaqt:
          {{ order.createdDate | date: 'HH:mm' }}
        </div>
        <div style="width: 100%; display: flex; flex-wrap: wrap">
          Ofitsiant: {{ order.createdBy.name ?? order.createdBy.username }}
        </div>
        <div
          style="width: 100%; display: flex; flex-wrap: wrap"
          *ngIf="order.paymentType">
          To'lov turi: {{ PaymentTypes[order.paymentType] }}
        </div>
        <div
          style="width: 100%; display: flex; flex-wrap: wrap"
          *ngIf="order.table">
          Stol: {{ order.table.name }}
        </div>
      </div>
      <div
        style="width: 100%; border-top: 2px dashed; border-bottom: 2px dashed">
        <div
          style="
            font-weight: 700;
            width: 100%;
            text-align: center;
            margin-top: 10px;
          ">
          <span>Buyurtma turi:</span>
          {{ OrderTypes[order.orderType] }}
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 10px 0;
          ">
          <table style="font-size: 10px">
            <tbody>
              <ng-container *ngFor="let item of order.items">
                <tr style="padding: 5px 0">
                  <td style="width: min-content">
                    {{ item.item.name }}
                  </td>
                  <td style="width: max-content">
                    {{ item.quantity }}
                  </td>
                  <td style="width: max-content; text-align: right">
                    {{ item.quantity * item.item.price | price }}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <!--          <div-->
          <!--            style="-->
          <!--              width: 100%;-->
          <!--              height: auto;-->
          <!--              display: flex;-->
          <!--              flex-direction: column;-->
          <!--              justify-content: space-between;-->
          <!--            "-->
          <!--            [style.border-bottom]="isLast ? 'none' : '2px solid #000'"-->
          <!--            [style.padding]="isLast ? '5px 0 0 0' : '5px 0'"-->
          <!--            *ngFor="let item of order.items; last as isLast">-->
          <!--            <div-->
          <!--              style="-->
          <!--                width: 100%;-->
          <!--                display: flex;-->
          <!--                justify-content: space-between;-->
          <!--                padding: 0;-->
          <!--              ">-->
          <!--              <span style="width: max-content">-->
          <!--                {{ item.item.name }}-->
          <!--              </span>-->
          <!--              <div-->
          <!--                style="-->
          <!--                  display: flex;-->
          <!--                  align-items: center;-->
          <!--                  width: max-content;-->
          <!--                  column-gap: 5px;-->
          <!--                ">-->
          <!--                <span>-->
          <!--                  {{ item.quantity }}-->
          <!--                </span>-->
          <!--                <span style="display: flex; justify-content: flex-end">-->
          <!--                  {{ item.quantity * item.item.price | price }}-->
          <!--                </span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
      <div style="width: 100%; border-bottom: 2px dashed">
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
          ">
          <span> Buyurtma </span>
          <span>{{ order.subtotal | price }}</span>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
          ">
          <span> Xizmat ({{ order.tipPercentage }}%) </span>
          <span>{{ order.tip | price }}</span>
        </div>
      </div>
      <div
        style="
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          font-weight: 900;
          font-size: 12px;
        ">
        <span>Jami:</span>
        <span>{{ order.totalPrice | price }}</span>
      </div>
    </div>
    <div style="width: 100%; display: flex; flex-direction: column">
      <div style="width: 100%; display: flex; flex-direction: column">
        <div
          style="width: 100%; display: flex"
          *ngIf="order.note && order.note.length > 0">
          <span style="font-weight: bold">Izoh:</span>{{ order.note }}
        </div>
        <div style="width: 100%; display: flex" *ngIf="order.customerPhone">
          <span style="font-weight: bold">Mijoz telfoni:</span>
          {{ order.customerPhone }}
        </div>
        <div
          style="width: 100%; display: flex"
          *ngIf="order.specialInstructions">
          <span style="font-weight: bold">Mijoz izohi:</span>
          {{ order.specialInstructions }}
        </div>
      </div>
      <div
        style="
          width: 100%;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        ">
        <div
          style="
            width: 100%;
            display: flex;
            margin-top: 10px;
            justify-content: center;
          ">
          <span>Buyurtmangiz uchun rahmat!</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
