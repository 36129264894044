import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MONTH, uzLatinMonth } from '../../../../models/datepicker.model';
import { icons } from '../../../../models/fontawesome';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements AfterViewInit {
  selectedDate?: Date;
  selectedMonth: string = new Date().getMonth().toString();
  calendarDate: Date = new Date();
  readonly Icons = icons;
  readonly uzLatinMonth = uzLatinMonth;
  monthData = Object.entries(uzLatinMonth);
  showMonthOption = false;
  @Input() set initialDate(value: unknown) {
    if (value && typeof value === 'string') {
      this.selectedDate = new Date(value);
    } else if (typeof value === 'object') {
      this.selectedDate = value as Date;
    } else {
      this.selectedDate = undefined;
    }

    if (this.selectedDate) {
      this.selectedMonth = this.selectedDate.getMonth().toString();
      this.calendarDate.setMonth(Number(this.selectedMonth));
    }
  }
  @Input() classNames = '';
  @Input() enableShortNames = false;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private elementRef: ElementRef) {}

  prevMonth(): void {
    this.calendarDate.setMonth(this.calendarDate.getMonth() - 1);
    this.selectedMonth = this.calendarDate.getMonth().toString();
  }

  getSelectedMonthName(): string {
    return this.enableShortNames
      ? this.uzLatinMonth[this.selectedMonth as unknown as MONTH].short_name
      : this.uzLatinMonth[this.selectedMonth as unknown as MONTH].full_name;
  }

  nextMonth(): void {
    this.calendarDate.setMonth(this.calendarDate.getMonth() + 1);
    this.selectedMonth = this.calendarDate.getMonth().toString();
  }

  handleSelectDate(date: Date): void {
    this.selectedDate = date;
    this.dateSelected.emit(this.selectedDate);
  }

  handleChangeMonth(month: string): void {
    this.selectedMonth = month;
    this.calendarDate = new Date(
      `${this.calendarDate.getFullYear()}-${Number(this.selectedMonth) + 1}-01`
    );
    this.showMonthOption = false;
  }

  ngAfterViewInit(): void {
    const calendar = document.getElementById(this.elementRef.nativeElement.id);
    const wrapper = document.getElementById(
      this.elementRef.nativeElement.offsetParent?.id
    );
    if (wrapper && calendar) {
      const left = wrapper.getBoundingClientRect().left;
      const right = wrapper.getBoundingClientRect().right;
      const width = wrapper.getBoundingClientRect().width;
      const calendarWidth = calendar.getBoundingClientRect().width;

      if (left + width - calendarWidth < 20) {
        calendar.style.left = '0px';
      } else if (right - width - calendarWidth < 20) {
        calendar.style.right = '0px';
      }
    }
  }

  getDaysInMonth(): (Date | undefined)[] {
    const year = this.calendarDate.getFullYear();
    const month = this.calendarDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const startWeekOfMonth = firstDay.getDay();
    const daysInMonth = [];

    if (startWeekOfMonth !== 1) {
      const restOfWeek = [1, 2, 3, 4, 5, 6, 0];
      for (let i = 0; i < restOfWeek.length; i++) {
        if (startWeekOfMonth === restOfWeek[i]) {
          break;
        }
        daysInMonth.push(undefined);
      }
    }

    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
      daysInMonth.push(new Date(day));
    }
    return daysInMonth;
  }
}
