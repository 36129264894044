import { Component, ViewContainerRef } from '@angular/core';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { NavigationService } from '../../services/navigation/navigation.service';
import { CommonService } from '../../../_services/common.service';
import { ModalService } from '../../../_services/modal.service';
import { Store } from '@ngrx/store';
import { loadCategories } from '../../../store/actions/category/category.action';
import { TableAction, UsersAction } from '../../../store/actions/index.action';

@Component({
  selector: 'app-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: [
    './desktop-layout.component.scss',
    '../app-layout/app-layout.component.scss',
  ],
})
export class DesktopLayoutComponent extends AppLayoutComponent {
  constructor(
    private navigation: NavigationService,
    private common: CommonService,
    private modal: ModalService,
    private viewContainerRef: ViewContainerRef,
    private store: Store
  ) {
    super(navigation, common, modal, viewContainerRef, store);
    this.store.dispatch(loadCategories());
    this.store.dispatch(TableAction.loadTablesFromLayout());
    this.store.dispatch(UsersAction.loadUsersFromLayout());
  }
}
