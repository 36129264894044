import { Component } from '@angular/core';
import { icons } from '../../../../models/fontawesome';

@Component({
  selector: 'app-logout-confirm-template',
  templateUrl: './logout-confirm-template.component.html',
  styleUrls: ['./logout-confirm-template.component.scss'],
})
export class LogoutConfirmTemplateComponent {
  readonly Icons = icons;
}
