<ng-container *ngIf="label; else noLabel">
  <div class="flex flex-col {{ className }}">
    <span class="text-gray-700">{{ label }}</span>
    <ng-container *ngIf="isFileUploadInactive; else fileUploadActiveTemplate">
      <label
        class="image-upload-wrapper mt-1 z-10"
        for="file-input"
        appDragAndDrop
        (fileDropped)="handleUploadImage($event)">
        <div
          class="flex flex-col items-center text-custom-gray-500 font-semibold z-0">
          <fa-icon
            class="text-xl mb-3"
            [icon]="Icons.arrowUpFromBracket"></fa-icon>
          <span class="text-sm">Faylni shu yerga tashlang yoki tanlang</span>
        </div>
        <input
          (change)="handleSelectFile($event)"
          type="file"
          hidden
          accept="image/*"
          id="file-input" />
      </label>
    </ng-container>
  </div>
</ng-container>

<ng-template #noLabel>
  <ng-container *ngIf="isFileUploadInactive; else fileUploadActiveTemplate">
    <label
      class="image-upload-wrapper mt-1 z-10"
      for="file-input-1"
      appDragAndDrop
      (fileDropped)="handleUploadImage($event)">
      <div
        class="flex flex-col items-center text-custom-gray-500 font-semibold z-0">
        <fa-icon
          class="text-xl mb-3"
          [icon]="Icons.arrowUpFromBracket"></fa-icon>
        <span class="text-sm">Faylni shu yerga tashlang yoki tanlang</span>
      </div>
      <input
        (change)="handleSelectFile($event)"
        type="file"
        hidden
        accept="image/*"
        id="file-input-1" />
    </label>
  </ng-container>
</ng-template>

<ng-template #fileUploadActiveTemplate>
  <div class="leading-1.5 flex w-full h-[150px] flex-col">
    <div class="group relative w-full h-full">
      <ng-container *ngIf="fileLoading">
        <div class="w-full md:w-1/2 h-full flex justify-center items-center">
          <fa-icon
            [icon]="Icons.spinner"
            animation="spin"
            class="text-lg"></fa-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="!fileLoading && fileUrl">
        <div
          class="absolute w-full md:w-1/2 h-full bg-gray-900/50 opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
          <button
            (click)="handleDeleteImage()"
            class="inline-flex items-center justify-center rounded-full h-10 w-10 bg-red-400 hover:bg-red-700/50">
            <fa-icon [icon]="Icons.trash" class="w-5 h-5 text-white"></fa-icon>
          </button>
        </div>
        <img
          [src]="fileUrl"
          class="aspect-square w-full md:w-1/2 h-full rounded-lg object-contain" />
      </ng-container>
    </div>
  </div>
</ng-template>
