import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoreAction, OrdersAction } from '../../actions/index.action';
import {
  catchError,
  concat,
  map,
  mergeMap,
  of,
  skipWhile,
  switchMap,
  tap,
  zipWith,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderClient } from '../../../_clients/order.client';
import { Store } from '@ngrx/store';
import {
  selectOrdersFilter,
  selectOrdersPagination,
} from '../../selectors/orders/orders.selector';

@Injectable()
export class OrdersEffect {
  constructor(
    private orderService: OrderClient,
    private actions$: Actions,
    private store$: Store
  ) {}

  loadOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrdersAction.loadOrders),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      zipWith(this.store$.select(selectOrdersPagination)),
      switchMap(([action, pagination]) => {
        return this.orderService
          .getAllWithPagination(
            `?size=${pagination.size}&page=${pagination.page}&sortField=createdDate&sortDirection=DESC`,
            action.filter
          )
          .pipe(
            map(response => {
              // TODO: find solution to dispatch action without using store$
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return OrdersAction.ordersLoadedSuccessfully({
                result: response,
                filter: action.filter,
              });
            }),
            catchError((err: HttpErrorResponse) => {
              // TODO: find solution to dispatch action without using store$
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return of(
                OrdersAction.orderRequestFailed({
                  error: String(err.error.message),
                })
              );
            })
          );
      })
    );
  });

  cancelOrder$ = createEffect(
    (store = this.store$) => {
      return this.actions$.pipe(
        ofType(OrdersAction.cancelOrder),
        tap(() => store.dispatch(CoreAction.loading({ loading: true }))),
        switchMap(action => {
          return this.orderService.cancel(action.id).pipe(
            map(response => {
              store.dispatch(CoreAction.loading({ loading: false }));
              return OrdersAction.cancelOrderSuccess({
                id: action.id,
                message: response.message,
              });
            }),
            catchError((err: HttpErrorResponse) => {
              store.dispatch(CoreAction.loading({ loading: false }));
              return of(
                OrdersAction.orderRequestFailed({
                  error: String(err.error.message),
                })
              );
            })
          );
        })
      );
    },
    {
      functional: true,
      dispatch: true,
    }
  );

  closeOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrdersAction.closeOrder),
      mergeMap(action =>
        concat(
          of(CoreAction.loading({ loading: true })),
          this.orderService.close(action.id, action.payload).pipe(
            map(response =>
              OrdersAction.closeOrderSuccess({
                id: action.id,
                message: response.message,
              })
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                OrdersAction.orderRequestFailed({ error: error.error.message })
              )
            )
          ),
          of(CoreAction.loading({ loading: false }))
        )
      )
    );
  });

  loadNextPageOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OrdersAction.nextPage),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      zipWith(
        this.store$.select(selectOrdersPagination).pipe(
          switchMap(pagination => {
            return this.store$
              .select(selectOrdersFilter)
              .pipe(map(filter => ({ pagination, filter })));
          })
        )
      ),
      skipWhile(
        ([, config]) => config.pagination.page >= config.pagination.totalPages
      ),
      switchMap(([, config]) => {
        return this.orderService
          .getAllWithPagination(
            `?size=${config.pagination.size}&page=${config.pagination.page}&sortField=createdDate&sortDirection=DESC`,
            config.filter
          )
          .pipe(
            map(response => {
              // TODO: find solution to dispatch action without using store$
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return OrdersAction.nextPageOrdersLoadedSuccessfully({
                result: response,
              });
            }),
            catchError((err: HttpErrorResponse) => {
              // TODO: find solution to dispatch action without using store$
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return of(
                OrdersAction.orderRequestFailed({
                  error: String(err.error.message),
                })
              );
            })
          );
      })
    );
  });
}
