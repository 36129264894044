import { AfterViewInit, Component, Input } from '@angular/core';
import { icons } from '../../../../models/fontawesome';
import { CommonService } from '../../../../_services/common.service';
import { ToasterPlacement, ToasterStatus } from '../../../../models/types';
@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements AfterViewInit {
  @Input() placement?: ToasterPlacement = 'top-right';
  Icons = icons;
  @Input() status?: ToasterStatus = 'success';
  @Input() title!: string;
  @Input() description? = '';
  @Input() duration!: number;

  constructor(private commonService: CommonService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.commonService.toaster$.next(null);
    }, this.duration);
  }

  handleClose(): void {
    this.commonService.toaster$.next(null);
  }
}
