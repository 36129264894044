<span
  [ngClass]="{
    'bg-primary-light': !selectedValuesMap.has(undefined),
    'bg-custom-gray-300': selectedValuesMap.has(undefined)
  }"
  appClickOutside
  (clickOutside)="showDropdown = false"
  class="relative flex items-center gap-x-1 rounded-full px-3 py-1"
  [class.justify-center]="label">
  <div
    class="cursor-pointer flex"
    [class.items-center]="label"
    [class.w-full]="!label"
    (click)="showDropdown = !showDropdown">
    <span *ngIf="label" class="text-sm font-normal text-custom-gray-500 mr-1">
      {{ label }}:
    </span>
    <span
      class="whitespace-nowrap text-sm font-medium text-custom-gray-600"
      *ngIf="!selectedValuesMap.size">
      {{ placeholder }}
    </span>

    <span
      [ngClass]="{
        'text-primary-dark': !selectedValuesMap.has(undefined),
        'text-custom-gray-600': selectedValuesMap.has(undefined)
      }"
      class="flex items-center whitespace-nowrap text-sm font-medium text-primary-dark"
      *ngIf="selectedValuesMap.size">
      {{ selectedValueLabels() }}
    </span>
  </div>

  <button
    type="button"
    *ngIf="selectedValuesMap.size && !selectedValuesMap.has(undefined)"
    (click)="handleRemove()"
    class="flex items-center justify-center py-0 px-1 cursor-pointer rounded-full text-[10px] bg-primary text-primary-light transition">
    <fa-icon [icon]="Icons.xmark"></fa-icon>
  </button>

  <div
    [ngClass]="{
      'opacity-100 visible': showDropdown,
      'opacity-0 invisible': !showDropdown
    }"
    class="bg-white border rounded-lg shadow-md min-w-[150px] max-h-[200px] overflow-y-auto transition-opacity inline-block absolute top-8 z-10">
    <ng-container *ngFor="let item of items">
      <div
        (click)="handleSelectValue(item)"
        class="flex items-center justify-between px-4 py-3 first:rounded-t-lg last:rounded-b-lg cursor-pointer hover:bg-primary-light transition-all">
        <span class="text-sm font-medium mr-5">
          {{ item.label }}
        </span>
        <fa-icon
          *ngIf="selectedValuesMap.has(item.value)"
          [icon]="Icons.squareCheckSolid"
          class="text-primary text-xs"></fa-icon>
      </div>
    </ng-container>
  </div>
</span>
