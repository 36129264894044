import { createSelector } from '@ngrx/store';
import { selectAppState } from '../../reducers/index.reducer';
import * as HistoryReducer from '../../reducers/history/history.reducer';

export const selectHistoryState = createSelector(
  selectAppState,
  (state): HistoryReducer.State => state[HistoryReducer.featureKey]
);

export const selectHistoryOrders = createSelector(
  selectHistoryState,
  state => state.orders
);

export const selectHistoryPagination = createSelector(
  selectHistoryState,
  state => state.pagination
);

export const selectHistoryFilter = createSelector(
  selectHistoryState,
  state => state.filter
);
