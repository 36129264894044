import { Injectable } from '@angular/core';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { ROLE } from '../../../models/enums';

export type TokenPayload = JwtPayload & {
  tenant: string;
  role: ROLE;
  user_id: number;
};

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor() {}

  decode(token: string): TokenPayload {
    return jwtDecode(token);
  }
}
