import { createAction, props } from '@ngrx/store';
import { Order, OrderFilter } from '../../../models/order.model';
import { ORDER_STATUS, PAYMENT_TYPE } from '../../../models/enums';
import { PaginationResponse } from '../../../core/services/BaseApiCall';

export const loadOrders = createAction(
  '[Orders Page] Load orders',
  props<{
    filter: OrderFilter;
  }>()
);

export const nextPage = createAction('[Orders Page] Load next page orders');

export const changeActiveTab = createAction(
  '[Orders Page] Change active status tab',
  props<{ tab: ORDER_STATUS }>()
);

export const cancelOrder = createAction(
  '[Orders Page] Cancel Order',
  props<{ id: number }>()
);

export const closeOrder = createAction(
  '[Orders Page] Close Order',
  props<{
    id: number;
    payload: { paymentType: PAYMENT_TYPE; discount: number };
  }>()
);

export const nextPageOrdersLoadedSuccessfully = createAction(
  '[API Call] Next page orders fetched successfully',
  props<{ result: PaginationResponse<Order[]> }>()
);

export const ordersLoadedSuccessfully = createAction(
  '[API Call] Order fetched successfully',
  props<{ result: PaginationResponse<Order[]>; filter: OrderFilter }>()
);

export const closeOrderSuccess = createAction(
  '[API Call] Order Closed Successfully',
  props<{ id: number; message: string }>()
);

export const orderRequestFailed = createAction(
  '[API Call] Order request failed',
  props<{ error: string }>()
);

export const cancelOrderSuccess = createAction(
  '[API Call] Order Canceled Successfully',
  props<{ id: number; message: string }>()
);
