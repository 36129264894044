import { Item } from './item.model';
import { ITEM_UNIT_TYPE, ORDER_TYPE } from './enums';

export class CartItem {
  product: Item;
  quantity: number;
  price: number;

  constructor(initialData: { product: Item; quantity: number; price: number }) {
    this.product = initialData.product;
    this.quantity = initialData.quantity;
    this.price = initialData.price;
  }

  increment(): void {
    if (
      this.product.unitType &&
      this.product.unitType === ITEM_UNIT_TYPE.WEIGHT_KILO
    ) {
      this.quantity += 0.5;
    } else {
      this.quantity += 1;
    }
    this.recalculatePrice();
  }

  decrement(): void {
    if (this.quantity >= 0.5) {
      if (
        this.product.unitType &&
        this.product.unitType === ITEM_UNIT_TYPE.WEIGHT_KILO
      ) {
        this.quantity -= 0.5;
      } else {
        this.quantity -= 1;
      }
      this.recalculatePrice();
    }
  }

  private recalculatePrice(): void {
    this.price = this.quantity * this.product.price;
  }
}

export class Cart {
  items: Map<number, CartItem>;
  note: string | null;
  tableId: number | null;
  orderType: ORDER_TYPE;
  customerPhone: string | null;
  specialInstructions: string | null;

  constructor(initialData: {
    items: Map<number, CartItem>;
    note: string | null;
    tableId: number | null;
    orderType: ORDER_TYPE;
    customerPhone: string | null;
    specialInstructions: string | null;
  }) {
    this.items = initialData.items;
    this.note = initialData.note;
    this.tableId = initialData.tableId;
    this.orderType = initialData.orderType;
    this.customerPhone = initialData.customerPhone;
    this.specialInstructions = initialData.specialInstructions;
  }

  addItem(data: Item): void {
    if (!this.items.has(data.id)) {
      const cartItem = new CartItem({
        product: data,
        quantity: 1,
        price: data.price,
      });
      this.items.set(data.id, cartItem);
    }
  }

  clear(): void {
    this.note = null;
    this.tableId = null;
    this.items = new Map();
    this.customerPhone = null;
    this.specialInstructions = null;
  }

  get totalPrice(): number {
    let total = 0;
    for (const item of this.items.values()) {
      total += item.price;
    }
    return total;
  }
}
