import { createAction, props } from '@ngrx/store';
import { Category, CategoryCreate } from '../../../models/category.model';

export const loadCategories = createAction('[App Layout] Load Categories');

export const loadCategoriesSuccessfully = createAction(
  '[API Call] Categories fetched successfully',
  props<{ result: Category[] }>()
);
export const loadCategoriesFailed = createAction(
  '[API Call] Categories fetch failed',
  props<{ error: string }>()
);
export const createCategory = createAction(
  '[API Call] Create category',
  props<{ payload: CategoryCreate }>()
);
export const categoryCreatedSuccessfully = createAction(
  '[API Call] Category created successfully',
  props<{ result: Category }>()
);
export const updateCategory = createAction(
  '[API Call] Update category',
  props<{ id: number; payload: CategoryCreate }>()
);

export const categoryUpdatedSuccessfully = createAction(
  '[API Call] Category updated successfully',
  props<{ result: Category }>()
);
export const deleteCategory = createAction(
  '[API Call] Delete category',
  props<{ id: number }>()
);
export const categoryDeletedSuccessfully = createAction(
  '[API Call] Category deleted successfully',
  props<{ id: number }>()
);
export const categoryApiCallFailed = createAction(
  '[API Call] Category api call failed',
  props<{ error: string }>()
);
