import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ToasterOptions } from '../models/types';
import { MenuService } from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private menuService: MenuService) {}
  windowWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(
    window.innerWidth
  );
  toaster$: ReplaySubject<ToasterOptions | null> = new ReplaySubject();

  clearAppState(): void {
    this.menuService.cart.clear();
    this.menuService.clear();
  }
}
