import { Component, OnInit } from '@angular/core';

import { IStaticMethods } from 'preline/preline';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsLoading } from './store/selectors/core/core.selector';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private store$: Store
  ) {}
  ngOnInit(): void {
    this.store$.select(selectIsLoading).subscribe(isLoading => {
      if (!isLoading) {
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 100);
      }
    });
  }
}
