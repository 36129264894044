import { Injectable } from '@angular/core';
import { Menu } from '../models/menu.model';
import { Category } from '../models/category.model';
import { Item } from '../models/item.model';
import { environment } from '../../environments/environment';
import { map, Observable, of } from 'rxjs';
import { Cart } from '../models/cart.model';
import { Order } from '../models/order.model';
import { ORDER_TYPE } from '../models/enums';
import { MenuClient } from '../_clients/menu.client';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private readonly baseUrl = environment.baseApiUrl;
  private _menu: Menu[] = [];
  private _categories: Category[] = [];
  private _items: Item[] = [];
  cart: Cart = new Cart({
    items: new Map(),
    note: null,
    tableId: null,
    orderType: ORDER_TYPE.IN_PLACE,
    customerPhone: null,
    specialInstructions: null,
  });
  selectedOrder: Order | undefined;

  get items(): Item[] {
    return this._items;
  }

  set items(value: Item[]) {
    this._items = value;
  }

  clear(): void {
    this._menu = [];
    this._categories = [];
    this._items = [];
  }

  constructor(private menuClient: MenuClient) {}

  getData(): Observable<{
    menu: Menu[];
    categories: Category[];
    items: Item[];
  }> {
    if (this._menu.length) {
      return of({
        menu: this._menu,
        categories: this._categories,
        items: this._items,
      });
    }
    return this.menuClient.get().pipe(
      map(result => {
        this._categories = result.map(menu => menu.category);
        this._items = result
          .map(menu => menu.items)
          .reduce((previousValue, currentValue) => {
            return [...previousValue, ...currentValue];
          }, []);
        this._menu = result;
        return {
          menu: result,
          categories: this._categories,
          items: this._items,
        };
      })
    );
  }
}
