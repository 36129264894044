import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { icons } from '../../../models/fontawesome';
import { DropdownOption } from '../../../models/types';

@Component({
  selector: 'app-options-dropdown',
  templateUrl: './options-dropdown.component.html',
  styleUrls: ['./options-dropdown.component.scss'],
})
export class OptionsDropdownComponent implements OnChanges, AfterViewInit {
  protected readonly Icons = icons;
  @Input() options!: DropdownOption[];
  @Input() placement: 'left' | 'right' = 'right';
  @Input() container: 'body' | 'document' = 'document';

  @Output() optionClick: EventEmitter<string> = new EventEmitter<string>();

  elementPosition: 'absolute' | 'fixed' = 'absolute';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.elementPosition === 'fixed' && this.container === 'body') {
      const currentElement = (this.elementRef.nativeElement as HTMLElement)
        .firstElementChild as HTMLElement;
      const parentEl = this.elementRef.nativeElement
        .parentElement as HTMLElement;
      const totalWidth = window.innerWidth;
      currentElement.style.right = `${
        totalWidth - parentEl.getBoundingClientRect().left - 20
      }px`;
      currentElement.style.top = `${
        parentEl.getBoundingClientRect().top + 10
      }px`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['container']?.currentValue &&
      changes['container']?.currentValue === 'body'
    ) {
      this.elementPosition = 'fixed';
    }
  }
}
