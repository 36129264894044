import { createSelector } from '@ngrx/store';
import { selectAppState } from '../../reducers/index.reducer';
import * as OrdersReducer from '../../reducers/orders/orders.reducer';

export const selectOrdersState = createSelector(
  selectAppState,
  (state): OrdersReducer.State => state[OrdersReducer.featureKey]
);

export const selectOrders = createSelector(
  selectOrdersState,
  state => state.records
);

export const selectOrder = (id: number) =>
  createSelector(selectOrdersState, state =>
    state.records.find(order => order.id == id)
  );

export const selectOrdersPagination = createSelector(
  selectOrdersState,
  state => state.pagination
);

export const selectOrdersFilter = createSelector(
  selectOrdersState,
  state => state.filter
);

export const selectOrdersActiveTab = createSelector(
  selectOrdersState,
  state => state.activeStatusTab
);
