<ng-container *ngIf="windowWidth$ | async | checkWidth: 'greater' : 'tablet'">
  <div class="relative w-full p-6">
    <div class="flex flex-col">
      <span class="text-2xl font-semibold mb-12">Parolni o'zgartirish</span>
      <div class="flex flex-col mb-12" [formGroup]="passwordForm">
        <app-input
          className="mb-8"
          [form]="passwordForm"
          control="oldPassword"
          formStyleType="solid"
          label="Eski parol"
          placeholder="Eski parolni kiriting..."
          inputType="password"></app-input>
        <app-input
          [form]="passwordForm"
          control="newPassword"
          formStyleType="solid"
          label="Yangi parol"
          placeholder="Yangi parolni kiriting..."
          inputType="password"></app-input>
      </div>
      <div class="flex items-center justify-end">
        <button
          (click)="handleSubmit()"
          [disabled]="passwordForm.invalid"
          class="w-2/5 py-3 rounded-xl bg-primary-dark text-white text-base font-semibold">
          <ng-container *ngIf="!loading; else loadingTemplate">
            Saqlash
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="windowWidth$ | async | checkWidth: 'less' : 'tablet'">
  <div class="relative h-full bg-white w-full">
    <div class="h-full w-full flex flex-col justify-between">
      <div class="flex flex-col mb-12 p-3" [formGroup]="passwordForm">
        <app-input
          className="mb-8"
          [form]="passwordForm"
          control="oldPassword"
          formStyleType="solid"
          label="Eski parol"
          placeholder="Eski parolni kiriting..."
          inputType="password"></app-input>
        <app-input
          [form]="passwordForm"
          control="newPassword"
          formStyleType="solid"
          label="Yangi parol"
          placeholder="Yangi parolni kiriting..."
          inputType="password"></app-input>
      </div>
      <div class="w-full h-[60px] flex items-center justify-center px-6">
        <button
          (click)="handleSubmit(true)"
          [disabled]="passwordForm.invalid"
          class="w-full py-3 rounded-xl bg-primary-dark text-white text-base font-semibold">
          <ng-container *ngIf="!loading; else loadingTemplate">
            Saqlash
          </ng-container>
        </button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #headerLeftSideTemplate>
  <fa-icon
    class="h-full text-custom-gray-500 text-lg flex items-center pl-4"
    [icon]="Icons.arrowLeft"
    (click)="handleBack()"></fa-icon>
</ng-template>

<ng-template #headerTitleTemplate>
  <div class="font-medium text-base tracking-widest text-black">
    Parolni o'zgartirish
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="flex justify-center items-center">
    <fa-icon [icon]="Icons.spinner" animation="spin" class="mr-3"></fa-icon>
    yuklanmoqda
  </div>
</ng-template>
