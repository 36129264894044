<label
  class="{{ className }}"
  [for]="inputId ?? 'toggleInput'"
  [formGroup]="form">
  <input
    [id]="inputId ?? 'toggleInput'"
    formControlName="{{ control }}"
    type="checkbox"
    checked />
  {{ label }}
</label>
