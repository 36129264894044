import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { icons } from '../../../../models/fontawesome';
import * as daterangepicker from 'daterangepicker';
import * as moment from 'moment';

declare let $: any;
@Component({
  selector: 'app-chip-datepicker',
  templateUrl: './chip-datepicker.component.html',
  styleUrls: ['./chip-datepicker.component.scss'],
})
export class ChipDatepickerComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    $(`#main-wrapper-` + this.calendarNumber)
      .daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        ranges: {
          Bugun: [moment(), moment()],
          Kecha: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        },
        showCustomRangeLabel: true,
        autoApply: true,
        opens: 'center',
        locale: {
          format: 'MM/DD/YYYY',
          separator: ' - ',
          applyLabel: 'Tanlash',
          cancelLabel: 'Bekor',
          fromLabel: 'Dan',
          toLabel: 'Gacha',
          customRangeLabel: 'Boshqa sana',
          weekLabel: 'H',
          daysOfWeek: ['Ya', 'Du', 'Se', 'Cho', 'Pa', 'Ju', 'Sha'],
          monthNames: [
            'Yanvar',
            'Fevral',
            'Mart',
            'Aprel',
            'May',
            'Iyun',
            'Iyul',
            'Avgust',
            'Sentyabr',
            'Oktyabr',
            'Noyabr',
            'Dekabr',
          ],
          firstDay: 1,
        },
        startDate: this.selectedDate,
        maxDate: moment().endOf('month').toDate(),
        minYear: new Date().getFullYear() - 5,
      })
      .on(
        'apply.daterangepicker',
        (ev: Event, picker: daterangepicker.DateRangePicker) => {
          this.handleSelectedDate(picker.startDate.toDate());
        }
      );
  }
  readonly Icons = icons;
  selectedDate?: Date;
  title = 'Sana';

  @Input() placeholder = 'Sana tanlang';
  @Input() calendarNumber!: number;
  @Input() set defaultDate(value: string | undefined | null) {
    if (value) {
      this.selectedDate = new Date(value);
    }
  }
  @Input() enableShortName = false;

  @Output()
  remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  handleSelectedDate(date: Date): void {
    this.selectedDate = date;
    this.selectedDateChanged.emit(date);
  }

  handleRemove(): void {
    this.selectedDate = undefined;
    this.remove.emit();
  }
}
