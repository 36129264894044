<ng-container *ngIf="label; else noLabel">
  <label class="block {{ className }}">
    <span class="text-gray-700">{{ label }}</span>
    <div class="w-full h-full relative">
      <ng-container *ngIf="prefixIcon">
        <fa-icon
          [icon]="Icons[prefixIcon]"
          [animation]="prefixIconAnimation"
          class="font-normal text-sm text-custom-gray-500 absolute left-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>
      <ng-container
        *ngIf="inputType === 'password'"
        [ngTemplateOutlet]="passwordInput"></ng-container>
      <ng-container
        *ngIf="inputType === 'text'"
        [ngTemplateOutlet]="textInput"></ng-container>
      <ng-container
        *ngIf="inputType === 'number'"
        [ngTemplateOutlet]="numberInput"></ng-container>
      <ng-container
        *ngIf="inputType === 'autocomplete'"
        [ngTemplateOutlet]="autocompleteInput"></ng-container>
      <ng-container *ngIf="suffixIcon">
        <fa-icon
          [icon]="Icons[suffixIcon]"
          [animation]="suffixIconAnimation"
          class="font-normal text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>
      <ng-container *ngIf="suffixIcon">
        <fa-icon
          [icon]="Icons[suffixIcon]"
          [animation]="suffixIconAnimation"
          class="font-normal text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>
    </div>

    <small *ngIf="errorText" class="text-red-400 text-xs">
      {{ errorText }}
    </small>
  </label>
</ng-container>

<ng-template #noLabel>
  <div class="{{ className }} flex flex-col" [formGroup]="form">
    <div class="w-full h-full relative">
      <ng-container *ngIf="prefixIcon">
        <fa-icon
          [icon]="Icons[prefixIcon]"
          [animation]="prefixIconAnimation"
          class="font-normal text-sm text-custom-gray-500 absolute left-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>
      <ng-container
        *ngIf="inputType === 'password'"
        [ngTemplateOutlet]="passwordInput"></ng-container>
      <ng-container
        *ngIf="inputType === 'text'"
        [ngTemplateOutlet]="textInput"></ng-container>
      <ng-container
        *ngIf="inputType === 'number'"
        [ngTemplateOutlet]="numberInput"></ng-container>
      <ng-container *ngIf="suffixIcon">
        <fa-icon
          [icon]="Icons[suffixIcon]"
          [animation]="suffixIconAnimation"
          class="font-normal text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>

      <ng-container *ngIf="suffixIcon">
        <fa-icon
          [icon]="Icons[suffixIcon]"
          [animation]="suffixIconAnimation"
          class="font-normal text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>
    </div>

    <small *ngIf="errorText" class="text-red-400 text-xs">
      {{ errorText }}
    </small>
  </div>
</ng-template>

<ng-template #numberInput>
  <ng-container [formGroup]="form">
    <input
      type="text"
      mask="separator"
      [attr.inputmode]="inputMode"
      (keydown.enter)="enter.emit()"
      formControlName="{{ control }}"
      [ngClass]="{
        'bg-gray-100': formStyleType === 'solid',
        'border-transparent': formStyleType === 'solid' && !errorText,
        'border-primary': formStyleType === 'simple',
        'focus:ring-primary-light focus:border-primary-light': !errorText,
        'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
          errorText,
        'pl-7': prefixIcon,
        'pr-7': suffixIcon
      }"
      class="mt-1 block w-full rounded-md focus:bg-white"
      [placeholder]="placeholder" />
  </ng-container>
</ng-template>

<ng-template #textInput>
  <ng-container [formGroup]="form">
    <input
      type="text"
      [attr.inputmode]="inputMode"
      (keydown.enter)="enter.emit()"
      formControlName="{{ control }}"
      [ngClass]="{
        'bg-gray-100': formStyleType === 'solid',
        'border-transparent': formStyleType === 'solid' && !errorText,
        'border-primary': formStyleType === 'simple',
        'focus:ring-primary-light focus:border-primary-light': !errorText,
        'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
          errorText,
        'pl-7': prefixIcon,
        'pr-7': suffixIcon
      }"
      class="mt-1 block w-full rounded-md focus:bg-white"
      [placeholder]="placeholder" />
  </ng-container>
</ng-template>

<ng-template #passwordInput>
  <ng-container [formGroup]="form">
    <input
      type="password"
      [attr.inputmode]="inputMode"
      (keydown.enter)="enter.emit()"
      formControlName="{{ control }}"
      [ngClass]="{
        'bg-gray-100': formStyleType === 'solid',
        'border-transparent': formStyleType === 'solid' && !errorText,
        'border-primary': formStyleType === 'simple',
        'focus:ring-primary-light focus:border-primary-light': !errorText,
        'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
          errorText,
        'pl-7': prefixIcon,
        'pr-7': suffixIcon
      }"
      class="mt-1 block w-full rounded-md focus:bg-white"
      [placeholder]="placeholder" />
  </ng-container>
</ng-template>

<ng-template #autocompleteInput>
  <ng-container [formGroup]="form">
    <input
      type="text"
      (input)="handleAutoCompleteInputChange($event)"
      [value]="form.get(control)?.value?.['text']"
      (keydown.enter)="handleEnterAutocompleteEvent($event)"
      [ngClass]="{
        'bg-gray-100': formStyleType === 'solid',
        'border-transparent': formStyleType === 'solid' && !errorText,
        'border-primary': formStyleType === 'simple',
        'focus:ring-primary-light focus:border-primary-light': !errorText,
        'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
          errorText,
        'pl-7': prefixIcon,
        'pr-7': suffixIcon
      }"
      [matAutocomplete]="auto"
      class="mt-1 block w-full rounded-md focus:bg-white"
      [placeholder]="placeholder" />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredAutocompleteItems"
        [value]="option.value"
        (click)="handleOptionSelected(option)">
        {{ option.text }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>
</ng-template>
