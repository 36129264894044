import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, authGuardForChild } from './core/guards/auth.guard';
import { ROLE } from './models/enums';
import { roleGuard } from './core/guards/role.guard';
import { AppLayoutComponent } from './core/layouts/app-layout/app-layout.component';
import { ProfileMainPageComponent } from './pages/profile/profile-main-page/profile-main-page.component';
import { PersonalInfoComponent } from './pages/profile/personal-info/personal-info.component';
import { ChangePasswordComponent } from './pages/profile/change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [authGuard],
    canActivateChild: [authGuardForChild],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
        },
        loadChildren: () =>
          import('./modules/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'menu',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CASHIER, ROLE.WAITER],
        },
        loadChildren: () =>
          import('./modules/menu/menu.module').then(m => m.MenuModule),
      },
      {
        path: 'orders',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CASHIER, ROLE.WAITER],
        },
        loadChildren: () =>
          import('./modules/orders/orders.module').then(m => m.OrdersModule),
      },
      {
        path: 'history',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
        },
        loadChildren: () =>
          import('./modules/history/history.module').then(m => m.HistoryModule),
      },
      {
        path: 'inventory',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
        },
        loadChildren: () =>
          import('./modules/inventory/inventory.module').then(
            m => m.InventoryModule
          ),
      },
      {
        path: 'settings',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN],
        },
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            m => m.SettingsModule
          ),
      },
      {
        path: 'profile',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CASHIER, ROLE.WAITER],
        },
        component: ProfileMainPageComponent,
        children: [
          {
            path: '',
            redirectTo: 'personal-info',
            pathMatch: 'full',
          },
          {
            path: 'personal-info',
            component: PersonalInfoComponent,
          },
          {
            path: 'change-password',
            component: ChangePasswordComponent,
          },
        ],
      },
      {
        path: 'profile/mobile',
        canActivate: [roleGuard],
        data: {
          roles: [ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.CASHIER, ROLE.WAITER],
        },
        children: [
          {
            path: '',
            component: ProfileMainPageComponent,
          },
          {
            path: 'personal-info',
            component: PersonalInfoComponent,
          },
          {
            path: 'change-password',
            component: ChangePasswordComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'auth/login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, useHash: false }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
