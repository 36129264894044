import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoryAction, CoreAction } from '../../actions/index.action';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CategoryClient } from '../../../_clients/category.client';

@Injectable()
export class CategoryEffect {
  constructor(
    private categoryService: CategoryClient,
    private readonly actions$: Actions
  ) {}

  loadCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryAction.loadCategories),
      tap(() => CoreAction.loading({ loading: true })),
      switchMap(action =>
        this.categoryService.getAll().pipe(
          map(result => {
            CoreAction.loading({ loading: false });
            return CategoryAction.loadCategoriesSuccessfully({ result });
          }),
          catchError((err: HttpErrorResponse) => {
            CoreAction.loading({ loading: false });
            return of(
              CategoryAction.loadCategoriesFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });

  createCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryAction.createCategory),
      tap(() => CoreAction.loading({ loading: true })),
      switchMap(action =>
        this.categoryService.create(action.payload).pipe(
          map(result => {
            CoreAction.loading({ loading: false });
            return CategoryAction.categoryCreatedSuccessfully({ result });
          }),
          catchError((err: HttpErrorResponse) => {
            CoreAction.loading({ loading: false });
            return of(
              CategoryAction.categoryApiCallFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });

  updateCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryAction.updateCategory),
      tap(() => CoreAction.loading({ loading: true })),
      switchMap(action =>
        this.categoryService.update(action.id, action.payload).pipe(
          map(result => {
            CoreAction.loading({ loading: false });
            return CategoryAction.categoryUpdatedSuccessfully({ result });
          }),
          catchError((err: HttpErrorResponse) => {
            CoreAction.loading({ loading: false });
            return of(
              CategoryAction.categoryApiCallFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });

  deleteCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryAction.deleteCategory),
      tap(() => CoreAction.loading({ loading: true })),
      switchMap(action =>
        this.categoryService.delete(action.id).pipe(
          map(result => {
            CoreAction.loading({ loading: false });
            return CategoryAction.categoryDeletedSuccessfully({
              id: action.id,
            });
          }),
          catchError((err: HttpErrorResponse) => {
            CoreAction.loading({ loading: false });
            return of(
              CategoryAction.categoryApiCallFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });
}
