<div class="w-full bg-white relative shadow rounded-[25px] p-4 md:p-6">
  <div class="w-full min-w-fit min-h-[95px] flex flex-col justify-between">
    <div class="w-full flex items-center justify-between mb-3">
      <span class="text-base font-semibold md:text-xl capitalize">
        <ng-content select="[title]"></ng-content>
      </span>
      <span class="text-xs font-normal md:text-base">
        <ng-content select="[additional-info]"></ng-content>
      </span>
    </div>
    <div class="w-full flex items-center">
      <ng-content></ng-content>
    </div>
  </div>
</div>
