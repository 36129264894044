import { Component, EventEmitter, Input, Output } from '@angular/core';
import { icons } from '../../../../models/fontawesome';

@Component({
  selector: 'app-chip-dropdown',
  templateUrl: './chip-dropdown.component.html',
  styleUrls: ['./chip-dropdown.component.scss'],
})
export class ChipDropdownComponent {
  readonly Icons = icons;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() items: { label: string; value: any }[] = [];
  @Input() set defaultValue(value: { label: string; value: any }) {
    this.selectedValue = value;
  }

  @Output() selectedValueChanged = new EventEmitter<{
    label: string;
    value: any;
  }>();

  showDropdown = false;
  selectedValue?: { label: string; value: any };

  handleSelectValue(item: { label: string; value: any }): void {
    this.selectedValue = item;
    this.showDropdown = false;
    this.selectedValueChanged.emit(this.selectedValue);
  }

  handleRemove(): void {
    this.selectedValue = this.items[0];
    this.selectedValueChanged.emit(this.selectedValue);
  }
}
