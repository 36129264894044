import { createSelector } from '@ngrx/store';
import { selectAppState } from '../../reducers/index.reducer';
import * as CoreReducer from '../../reducers/core/core.reducer';

export const selectCoreState = createSelector(
  selectAppState,
  (state): CoreReducer.State => state[CoreReducer.featureKey]
);

export const selectIsLoading = createSelector(
  selectCoreState,
  state => state.isLoading
);

export const selectErrorMessage = createSelector(
  selectCoreState,
  state => state.errorMessage
);

export const selectAuthorizedUser = createSelector(
  selectCoreState,
  state => state.authorizedUser
);
