import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowRightLong,
  faArrowRightToBracket,
  faArrowUpFromBracket,
  faArrowUpRightFromSquare,
  faBan,
  faBellConcierge,
  faBoxesStacked,
  faBoxOpen,
  faCartShopping,
  faCashRegister,
  faChartBar,
  faCheck,
  faChevronRight,
  faCircleCheck,
  faCircleDollarToSlot,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCircleUser,
  faCircleXmark,
  faClock,
  faCouch,
  faCreditCard,
  faDolly,
  faEllipsis,
  faEllipsisVertical,
  faGear,
  faGrip,
  faHome,
  faLayerGroup,
  faListCheck,
  faLock,
  faMagnifyingGlass,
  faMinus,
  faMoneyBill1,
  faPen,
  faPercent,
  faPersonWalking,
  faPlus,
  faRightFromBracket,
  faShapes,
  faSliders,
  faSpinner,
  faSquareCheck,
  faSquarePen,
  faStore,
  faStroopwafel,
  faTrash,
  faTriangleExclamation,
  faTruckFast,
  faUserGear,
  faUsers,
  faXmark,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays as faCalendarDaysRegular } from '@fortawesome/free-regular-svg-icons';

export type IconNames =
  | 'arrowUpRightFromSquare'
  | 'listCheck'
  | 'gear'
  | 'home'
  | 'arrowRightToBracket'
  | 'grip'
  | 'stroopwafel'
  | 'couch'
  | 'users'
  | 'cashRegister'
  | 'store'
  | 'cartShopping'
  | 'plus'
  | 'minus'
  | 'bellConcierge'
  | 'spinner'
  | 'circleCheck'
  | 'circleXmark'
  | 'circleInfo'
  | 'circleExclamation'
  | 'triangleExclamation'
  | 'xmark'
  | 'circlePlus'
  | 'squarePen'
  | 'boxOpen'
  | 'moneyBill1'
  | 'creditCard'
  | 'ellipsisVertical'
  | 'ellipsis'
  | 'pen'
  | 'ban'
  | 'percent'
  | 'circleDollarToSlot'
  | 'clock'
  | 'magnifyingGlass'
  | 'circleUser'
  | 'userGear'
  | 'lock'
  | 'rightFromBracket'
  | 'shapes'
  | 'trash'
  | 'arrowUpFromBracket'
  | 'arrowLeft'
  | 'arrowRight'
  | 'arrowRightLong'
  | 'chevronRight'
  | 'sliders'
  | 'personWalking'
  | 'truckFast'
  | 'layerGroup'
  | 'calendarDaysRegular'
  | 'angleDown'
  | 'check'
  | 'squareCheckSolid'
  | 'angleLeft'
  | 'angleRight'
  | 'dolly'
  | 'chartBar'
  | 'boxesStacked'
  | 'circleMinus';

export const icons: Record<IconNames, IconDefinition> = {
  arrowUpRightFromSquare: faArrowUpRightFromSquare,
  listCheck: faListCheck,
  gear: faGear,
  home: faHome,
  arrowRightToBracket: faArrowRightToBracket,
  grip: faGrip,
  stroopwafel: faStroopwafel,
  couch: faCouch,
  users: faUsers,
  cashRegister: faCashRegister,
  store: faStore,
  cartShopping: faCartShopping,
  plus: faPlus,
  minus: faMinus,
  bellConcierge: faBellConcierge,
  spinner: faSpinner,
  circleCheck: faCircleCheck,
  circleXmark: faCircleXmark,
  circleInfo: faCircleInfo,
  circleExclamation: faCircleExclamation,
  xmark: faXmark,
  circlePlus: faCirclePlus,
  squarePen: faSquarePen,
  boxOpen: faBoxOpen,
  moneyBill1: faMoneyBill1,
  creditCard: faCreditCard,
  ellipsisVertical: faEllipsisVertical,
  pen: faPen,
  ban: faBan,
  percent: faPercent,
  circleDollarToSlot: faCircleDollarToSlot,
  clock: faClock,
  magnifyingGlass: faMagnifyingGlass,
  circleUser: faCircleUser,
  userGear: faUserGear,
  lock: faLock,
  rightFromBracket: faRightFromBracket,
  shapes: faShapes,
  trash: faTrash,
  arrowUpFromBracket: faArrowUpFromBracket,
  arrowLeft: faArrowLeft,
  arrowRight: faArrowRight,
  arrowRightLong: faArrowRightLong,
  chevronRight: faChevronRight,
  sliders: faSliders,
  personWalking: faPersonWalking,
  truckFast: faTruckFast,
  layerGroup: faLayerGroup,
  triangleExclamation: faTriangleExclamation,
  calendarDaysRegular: faCalendarDaysRegular,
  angleDown: faAngleDown,
  check: faCheck,
  squareCheckSolid: faSquareCheck,
  angleLeft: faAngleLeft,
  angleRight: faAngleRight,
  dolly: faDolly,
  ellipsis: faEllipsis,
  chartBar: faChartBar,
  boxesStacked: faBoxesStacked,
  circleMinus: faCircleMinus,
};
