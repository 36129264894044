<div class="layout-wrapper font-poppins">
  <div class="layout-drawer">
    <div class="logo">
      <span class="font-semibold tracking-wide">ORDIN</span>
    </div>
    <div class="navigation-list">
      <ul class="dynamic-list">
        <ng-container
          *ngFor="
            let navigation of getNavigationList((authorizedUser | async)!)
          ">
          <li
            class="navigation-item"
            [routerLink]="navigation.route"
            routerLinkActive="active-navigation-item">
            <fa-icon
              class="mr-3 md:mr-0"
              [icon]="Icons[navigation.icon]"></fa-icon>
            <span class="tracking-wide">
              {{ navigation.title }}
            </span>
          </li>
        </ng-container>
      </ul>
      <div
        routerLink="profile"
        routerLinkActive="active-navigation-item"
        class="h-max navigation-item bg-white">
        <fa-icon class="mr-3 md:mr-0" [icon]="Icons.circleUser"></fa-icon>
        <span class="tracking-wide">Profil</span>
      </div>
    </div>
  </div>
  <div class="layout-content">
    <div class="layout-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
