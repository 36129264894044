<ng-container *ngIf="label; else noLabel">
  <div class="block {{ className }}">
    <span class="text-gray-700">{{ label }}</span>
    <div class="w-full relative" id="select-wrapper">
      <div
        (click)="toggleDropdown()"
        [ngClass]="{
          'bg-gray-100': formStyleType === 'solid',
          'border-transparent': formStyleType === 'solid' && !errorText,
          'border-primary': formStyleType === 'simple',
          'focus:ring-primary-light focus:border-primary-light': !errorText,
          'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
            errorText,
          'py-2': selectedItems.size > 2
        }"
        class="mt-1 min-h-[42px] flex items-center cursor-pointer pl-3 pr-7 w-full rounded-md focus:bg-white">
        <ng-container *ngIf="selectedItems.size; else placeholderTemp">
          <div class="flex items-center flex-wrap gap-3">
            <ng-container *ngFor="let selectedItem of selectedValues">
              <div
                class="flex justify-between items-center bg-primary rounded-xl text-primary-light px-2 py-0">
                <span class="mr-3">{{ selectedItem.text }}</span>
                <fa-icon
                  (click)="toggleSelectedItems(selectedItem)"
                  [icon]="Icons.circleXmark"></fa-icon>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <fa-icon
        [icon]="Icons.angleDown"
        [class.rotate-180]="isOptionsOpen"
        class="font-normal transition-transform duration-300 text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
      <div
        *ngIf="isOptionsOpen"
        appClickOutside
        actionElId="select-wrapper"
        (clickOutside)="toggleDropdown()"
        class="absolute z-10 max-h-[150px] overflow-y-auto w-full mt-1 shadow-lg rounded-xl border border-gray-200 bg-white flex flex-col py-4">
        <ng-container *ngFor="let item of items">
          <div
            (click)="toggleSelectedItems(item)"
            class="text-base flex items-center justify-between py-2 px-3 text-custom-gray-600 transition-all duration-300 cursor-pointer hover:bg-gray-100">
            <span>{{ item.text }}</span>
            <fa-icon
              *ngIf="selectedItems.has(item.value)"
              [icon]="Icons.check"
              class="text-sm text-green-500"></fa-icon>
          </div>
        </ng-container>
      </div>
    </div>

    <small *ngIf="errorText" class="text-red-400 text-xs">
      {{ errorText }}
    </small>
  </div>
</ng-container>

<ng-template #noLabel>
  <div class="{{ className }} flex flex-col">
    <div class="w-full relative">
      <div
        [ngClass]="{
          'bg-gray-100': formStyleType === 'solid',
          'border-transparent': formStyleType === 'solid' && !errorText,
          'border-primary': formStyleType === 'simple',
          'focus:ring-primary-light focus:border-primary-light': !errorText,
          'focus:ring-red-400 ring-red-400 focus:border-red-400 border-red-400':
            errorText
        }"
        class="block h-[42px] flex items-center cursor-pointer pl-5 pr-7 w-full rounded-md focus:bg-white">
        <span class="text-gray-300 text-base">{{ placeholder }}</span>
      </div>
      <fa-icon
        [icon]="Icons.angleDown"
        [class.rotate-180]="isOptionsOpen"
        class="font-normal transition-transform duration-300 text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
    </div>

    <small *ngIf="errorText" class="text-red-400 text-xs">
      {{ errorText }}
    </small>
  </div>
</ng-template>

<ng-template #placeholderTemp>
  <span class="text-gray-300 text-base">
    {{ placeholder }}
  </span>
</ng-template>
