import { Injectable } from '@angular/core';
import { BaseApiCall } from '../core/services/BaseApiCall';
import { Category, CategoryCreate } from '../models/category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryClient extends BaseApiCall<Category, CategoryCreate> {
  constructor() {
    super('categories');
  }
}
