<div class="w-full h-full bg-white relative p-3">
  <div class="flex flex-col divide-y">
    <div
      routerLink="personal-info"
      routerLinkActive="active-link"
      class="flex items-center justify-between text-base font-normal text-custom-gray-500 pt-2.5 pb-5 px-6 cursor-pointer">
      <div class="flex items-center">
        <fa-icon [icon]="Icons.userGear" class="mr-3"></fa-icon>
        <span>Shaxsiy ma'lumot</span>
      </div>
      <fa-icon [icon]="Icons.chevronRight"></fa-icon>
    </div>
    <div
      routerLink="change-password"
      routerLinkActive="active-link"
      class="flex items-center justify-between text-base font-normal text-custom-gray-500 py-5 px-6 cursor-pointer">
      <div class="flex items-center">
        <fa-icon [icon]="Icons.lock" class="mr-3"></fa-icon>
        <span>Parol o'zgartirish</span>
      </div>
      <fa-icon [icon]="Icons.chevronRight"></fa-icon>
    </div>
    <div
      (click)="handleLogout()"
      class="flex items-center text-base font-normal text-custom-gray-500 py-5 px-6 cursor-pointer">
      <fa-icon [icon]="Icons.rightFromBracket" class="mr-3"></fa-icon>
      <span>Chiqish</span>
    </div>
  </div>
</div>
