import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CoreAction, UsersAction } from '../../actions/index.action';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserClient } from '../../../_clients/user.client';

@Injectable()
export class UsersEffect {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private userService: UserClient
  ) {}

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UsersAction.loadUsers,
        UsersAction.loadUsersFromHistoryPage,
        UsersAction.loadUsersFromLayout
      ),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      switchMap(action =>
        this.userService.getAll().pipe(
          map(response => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return UsersAction.usersLoadedSuccessfully({
              result: response,
            });
          }),
          catchError((err: HttpErrorResponse) => {
            this.store$.dispatch(CoreAction.loading({ loading: false }));
            return of(
              UsersAction.usersLoadFailed({
                error: String(err.error.message),
              })
            );
          })
        )
      )
    );
  });
}
