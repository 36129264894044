import { createReducer, on } from '@ngrx/store';
import { OrdersAction } from '../../actions/index.action';
import { Order, OrderFilter } from '../../../models/order.model';
import { ORDER_STATUS } from '../../../models/enums';

export const featureKey = 'orders';
export interface State {
  records: Order[];
  errorMessage?: string;
  activeStatusTab: ORDER_STATUS;
  pagination: {
    page: number;
    size: number;
    totalPages: number;
    totalElements?: number;
  };
  filter: OrderFilter;
}
export const initialState: State = {
  records: [],
  errorMessage: undefined,
  activeStatusTab: ORDER_STATUS.STARTED,
  pagination: {
    page: 1,
    size: 10,
    totalPages: 1,
  },
  filter: {
    statuses: [ORDER_STATUS.STARTED],
  },
};

export const reducer = createReducer<State>(
  initialState,
  on(OrdersAction.nextPage, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page:
          state.pagination.page < state.pagination.totalPages
            ? state.pagination.page + 1
            : state.pagination.page,
      },
    };
  }),
  on(OrdersAction.ordersLoadedSuccessfully, (state, action) => {
    return {
      ...state,
      filter: action.filter,
      records: action.result.content,
      pagination: {
        ...state.pagination,
        totalElements: action.result.totalElements,
        totalPages: action.result.totalPages,
      },
    };
  }),
  on(OrdersAction.closeOrderSuccess, (state, action) => {
    return {
      ...state,
      records: state.records.filter(record => record.id !== action.id),
    };
  }),
  on(OrdersAction.nextPageOrdersLoadedSuccessfully, (state, action) => {
    return {
      ...state,
      records: [...state.records, ...action.result.content],
      pagination: {
        ...state.pagination,
        totalElements: action.result.totalElements,
        totalPages: action.result.totalPages,
      },
    };
  }),
  on(OrdersAction.orderRequestFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  }),
  on(OrdersAction.changeActiveTab, (state, action) => {
    return {
      ...state,
      activeStatusTab: action.tab,
      pagination: {
        page: 1,
        size: 10,
        totalPages: 1,
      },
      filter: {
        ...state.filter,
        statuses: [action.tab],
      },
    };
  }),
  on(OrdersAction.cancelOrderSuccess, (state, action) => {
    return {
      ...state,
      records: state.records.filter(record => record.id !== action.id),
    };
  })
);
