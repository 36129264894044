import { createAction, props } from '@ngrx/store';
import { User } from '../../../models/user.model';

export const login = createAction(
  '[Login page] Submit login form',
  props<{ username: string; password: string }>()
);

export const loginSuccess = createAction(
  '[API Call] login successfully to app',
  props<{ user: User }>()
);

export const loginFail = createAction(
  '[API Call] login failed',
  props<{ error: string }>()
);

export const loading = createAction(
  '[App Loading] api calling',
  props<{ loading: boolean }>()
);

export const logout = createAction('[Profile page] Logout from app');
