import { Component, Input } from '@angular/core';
import { icons } from '../../../../models/fontawesome';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent {
  @Input() form!: FormGroup;
  @Input() control!: string;
  @Input() label = '';
  @Input() inputId?: string;
  @Input() className?: string;

  readonly Icons = icons;
  isActive = false;
  handleToggle(): void {
    this.isActive = !this.isActive;
  }
}
