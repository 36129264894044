<div
  class="relative bg-white rounded-xl py-3"
  [class.shadow]="shadow"
  [class.overflow-x-auto]="overflowX">
  <table class="w-full text-sm text-left text-gray-500">
    <thead class="text-xs text-gray-700 uppercase">
      <tr>
        <ng-container *ngFor="let column of columns">
          <ng-container
            *ngIf="isNameColumn(column) || isNameTemplateColumn(column)">
            <th
              scope="col"
              class="{{ column.classNames }}"
              [attr.colspan]="column.colspan || 1">
              <ng-container *ngIf="isNameColumn(column)">
                <div [innerHTML]="column.name"></div>
              </ng-container>
              <ng-container
                *ngIf="isNameTemplateColumn(column)"
                [ngTemplateOutlet]="column.nameTemplate"></ng-container>
            </th>
          </ng-container>
        </ng-container>
      </tr>
    </thead>
    <tbody class="{{ appearance }}">
      <ng-container *ngIf="rows.length > 0">
        <ng-container *ngFor="let row of rows; let i = index">
          <ng-container
            *ngTemplateOutlet="
              rowTemplate;
              context: { row, index: i }
            "></ng-container>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot *ngIf="!rows || rows?.length === 0">
      <tr>
        <td [attr.colspan]="columns.length">
          <div
            class="h-full min-h-[150px] flex justify-center items-center text-custom-gray-600">
            <fa-icon [icon]="Icons.boxOpen" class="mr-2"></fa-icon>
            <span class="text-sm font-semibold">Jadval bo'sh</span>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
