import { Category } from '../../../models/category.model';
import { createReducer, on } from '@ngrx/store';
import { CategoryAction } from '../../actions/index.action';

export const featureKey = 'category';
export interface State {
  errorMessage?: string;
  records: Category[];
}
export const initialState: State = {
  errorMessage: undefined,
  records: [],
};

export const reducer = createReducer<State>(
  initialState,
  on(CategoryAction.loadCategoriesSuccessfully, (state, action) => {
    return {
      ...state,
      records: action.result,
    };
  }),
  on(CategoryAction.loadCategoriesFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  }),
  on(CategoryAction.categoryCreatedSuccessfully, (state, action) => {
    return {
      ...state,
      records: [...state.records, action.result],
    };
  }),
  on(CategoryAction.categoryUpdatedSuccessfully, (state, action) => {
    return {
      ...state,
      records: state.records.map(category => {
        if (category.id === action.result.id) {
          return action.result;
        }
        return category;
      }),
    };
  }),
  on(CategoryAction.categoryDeletedSuccessfully, (state, action) => {
    return {
      ...state,
      records: state.records.filter(category => category.id !== action.id),
    };
  }),
  on(CategoryAction.categoryApiCallFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  })
);
