<ng-container *ngIf="label; else noLabel">
  <form [formGroup]="form">
    <label class="block">
      <span class="text-gray-700">{{ label }}</span>
      <textarea
        [rows]="rows"
        formControlName="{{ control }}"
        [ngClass]="{
          'bg-gray-100 border-transparent': formStyleType === 'solid',
          'border-primary': formStyleType === 'simple'
        }"
        class="mt-1 block w-full rounded-md focus:border-primary-light focus:bg-white"
        [placeholder]="placeholder">
      </textarea>
      <small *ngIf="errorText" class="text-red-400 text-xs">
        {{ errorText }}
      </small>
    </label>
  </form>
</ng-container>

<ng-template #noLabel [formGroup]="form">
  <form [formGroup]="form">
    <textarea
      [rows]="rows"
      formControlName="{{ control }}"
      [ngClass]="{
        'bg-gray-100 border-transparent': formStyleType === 'solid',
        'border-primary': formStyleType === 'simple'
      }"
      class="mt-1 block w-full rounded-md focus:border-primary-light focus:bg-white"
      [placeholder]="placeholder">
    </textarea>
    <small *ngIf="errorText" class="text-red-400 text-xs">
      {{ errorText }}
    </small>
  </form>
</ng-template>
