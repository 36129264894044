import { Injectable } from '@angular/core';
import { AuthService } from '../../../core/services/auth/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CoreAction } from '../../actions/index.action';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';

@Injectable()
export class CoreEffect {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private store$: Store
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreAction.login),
      switchMap(action => {
        // TODO: find solution to dispatch action without using store$
        this.store$.dispatch(CoreAction.loading({ loading: true }));
        return this.authService
          .login({
            password: action.password,
            username: action.username,
          })
          .pipe(
            tap(response => {
              this.authService.user = response.user;
              this.authService.token = response.token;
            }),
            map(response => CoreAction.loginSuccess({ user: response.user })),
            catchError((err: HttpErrorResponse) =>
              of(CoreAction.loginFail({ error: err.error.message }))
            )
          );
      })
    );
  });
}
