import { createAction, props } from '@ngrx/store';
import {
  ComponentCreate,
  ComponentResponse,
} from '../../../models/component.model';

export const loadComponents = createAction('[Warehouse Page] Load Components');

export const createComponent = createAction(
  '[Warehouse Page] Submit Create Component Form',
  props<{ payload: ComponentCreate }>()
);

export const updateComponent = createAction(
  '[Warehouse Page] Submit Update Component Form',
  props<{ id: number; payload: ComponentCreate }>()
);

export const deleteComponent = createAction(
  '[Warehouse Page] Delete Component',
  props<{ id: number }>()
);

export const loadComponentSuccess = createAction(
  '[API Call] Components loaded successfully',
  props<{ records: ComponentResponse[] }>()
);

export const createComponentSuccess = createAction(
  '[API Call] Component created successfully',
  props<{ record: ComponentResponse }>()
);

export const updateComponentSuccess = createAction(
  '[API Call] Component updated successfully',
  props<{ record: ComponentResponse }>()
);

export const deleteComponentSuccess = createAction(
  '[API Call] Component deleted successfully',
  props<{ id: number }>()
);

export const requestFailed = createAction(
  '[API Call] Component request failed',
  props<{ error: string }>()
);
