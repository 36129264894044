import { Pipe, PipeTransform } from '@angular/core';
import { customTabletWidth } from '../../models/constants';

@Pipe({
  name: 'checkWidth',
})
export class CheckWidthPipe implements PipeTransform {
  transform(
    value: number | null | undefined,
    condition: 'less' | 'greater' = 'less',
    type: 'tablet' = 'tablet'
  ): boolean {
    if (value === null || value === undefined) return false;
    if (type === 'tablet') {
      return condition === 'less'
        ? value < customTabletWidth
        : value > customTabletWidth;
    }
    return false;
  }
}
