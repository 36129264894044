import { User } from '../../../models/user.model';
import { createReducer, on } from '@ngrx/store';
import { UsersAction } from '../../actions/index.action';

export const featureKey = 'users';

export interface State {
  records: User[];
  errorMessage?: string;
}

export const initialState: State = {
  records: [],
  errorMessage: undefined,
};

export const reducer = createReducer<State>(
  initialState,
  on(UsersAction.usersLoadedSuccessfully, (state, action) => {
    return {
      ...state,
      records: action.result,
    };
  }),
  on(UsersAction.usersLoadFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  })
);
