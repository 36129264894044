import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTrashCan, faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
})
export class ListCardComponent {
  @Input() title!: string;
  @Input() loading = false;

  @Output() edit: EventEmitter<void> = new EventEmitter<void>();
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();

  deleteIcon = faTrashCan;
  editIcon = faPen;
}
