import { Component, Input } from '@angular/core';
import { DefaultValueAccessor, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent extends DefaultValueAccessor {
  @Input() form!: FormGroup;
  @Input() control!: string;
  @Input() formStyleType: 'solid' | 'simple' = 'simple';
  @Input() label: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() rows = 3;
  @Input() errorText: string | undefined;
}
