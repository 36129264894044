import { Component } from '@angular/core';
import { icons } from '../../../models/fontawesome';
import { AuthService } from '../../../core/services/auth/auth.service';
import { CommonService } from '../../../_services/common.service';
import { Observable } from 'rxjs';
import { ModalService } from '../../../_services/modal.service';
import { LogoutConfirmTemplateComponent } from '../_components/logout-confirm-template/logout-confirm-template.component';

@Component({
  selector: 'app-profile-main-page',
  templateUrl: './profile-main-page.component.html',
  styleUrls: ['./profile-main-page.component.scss'],
})
export class ProfileMainPageComponent {
  Icons = icons;
  get windowWidth$(): Observable<number> {
    return this.common.windowWidth$;
  }

  constructor(
    private auth: AuthService,
    private common: CommonService,
    private modal: ModalService
  ) {}

  handleLogout(): void {
    this.modal
      .openWithComponent(LogoutConfirmTemplateComponent, {
        title: 'Chiqish',
        showCancel: true,
        kind: 'danger',
      })
      ?.subscribe(result => {
        if (result === 'confirm') {
          this.auth.logout();
        }
      });
  }
}
