import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CoreAction, HistoryAction } from '../../actions/index.action';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  takeWhile,
  tap,
  zip,
  zipWith,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  selectHistoryFilter,
  selectHistoryPagination,
} from '../../selectors/history/history.selector';
import { OrderClient } from '../../../_clients/order.client';

@Injectable()
export class HistoryEffect {
  constructor(
    private orderClient: OrderClient,
    private actions$: Actions,
    private store$: Store
  ) {}

  loadOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HistoryAction.loadOrders),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      zipWith(this.store$.select(selectHistoryPagination)),
      switchMap(([action, pagination]) => {
        return this.orderClient
          .getAllWithPagination(
            `?size=${pagination.size}&page=${pagination.page}&sortField=createdDate&sortDirection=DESC`,
            action.filter
          )
          .pipe(
            map(response => {
              // TODO: find solution to dispatch action without using store$
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return HistoryAction.ordersLoadedSuccessfully({
                result: response,
                filter: action.filter,
              });
            }),
            catchError((err: HttpErrorResponse) => {
              // TODO: find solution to dispatch action without using store$
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return of(
                HistoryAction.orderLoadFailed({
                  error: String(err.error.message),
                })
              );
            })
          );
      })
    );
  });

  loadChangedPageOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HistoryAction.changePagination),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      switchMap(() =>
        zip(
          this.store$.select(selectHistoryPagination),
          this.store$.select(selectHistoryFilter)
        )
      ),
      takeWhile(([pagination]) => {
        return pagination.page <= pagination.totalPages;
      }),
      exhaustMap(([pagination, filter]) => {
        return this.orderClient
          .getAllWithPagination(
            `?size=${pagination.size}&page=${pagination.page}&sortField=createdDate&sortDirection=DESC`,
            filter
          )
          .pipe(
            mergeMap(response => [
              HistoryAction.paginateOrdersLoadedSuccessfully({
                result: response,
              }),
              CoreAction.loading({ loading: false }),
            ]),
            catchError((err: HttpErrorResponse) => {
              return of(
                HistoryAction.orderLoadFailed({
                  error: String(err.error.message),
                }),
                CoreAction.loading({ loading: false })
              );
            })
          );
      })
    );
  });

  loadNextScrollPageOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(HistoryAction.nextInfiniteScrollPage),
      tap(() => this.store$.dispatch(CoreAction.loading({ loading: true }))),
      switchMap(() =>
        zip(
          this.store$.select(selectHistoryPagination),
          this.store$.select(selectHistoryFilter)
        )
      ),
      takeWhile(([pagination]) => pagination.page <= pagination.totalPages),
      exhaustMap(([pagination, filter]) =>
        this.orderClient
          .getAllWithPagination(
            `?size=${pagination.size}&page=${pagination.page}&sortField=createdDate&sortDirection=DESC`,
            filter
          )
          .pipe(
            map(response => {
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return HistoryAction.nextInfiniteScrollPageLoadedSuccess({
                result: response,
              });
            }),
            catchError((err: HttpErrorResponse) => {
              this.store$.dispatch(CoreAction.loading({ loading: false }));
              return of(
                HistoryAction.orderLoadFailed({
                  error: String(err.error.message),
                })
              );
            })
          )
      )
    );
  });
}
