import { ComponentResponse } from '../../../models/component.model';
import { createReducer, on } from '@ngrx/store';
import { WarehouseAction } from '../../actions/index.action';

export const featureKey = 'components';

export interface State {
  records: ComponentResponse[];
  errorMessage?: string;
}

export const initialState: State = {
  records: [],
  errorMessage: undefined,
};

export const reducer = createReducer<State>(
  initialState,
  on(WarehouseAction.loadComponentSuccess, (state, action) => {
    return {
      ...state,
      records: action.records,
    };
  }),
  on(WarehouseAction.createComponentSuccess, (state, action) => {
    return {
      ...state,
      records: [...state.records, action.record],
    };
  }),
  on(WarehouseAction.updateComponentSuccess, (state, action) => {
    return {
      ...state,
      records: state.records.map(record => {
        if (record.id === action.record.id) {
          return action.record;
        }
        return record;
      }),
    };
  }),
  on(WarehouseAction.deleteComponentSuccess, (state, action) => {
    return {
      ...state,
      records: state.records.filter(record => record.id !== action.id),
    };
  }),
  on(WarehouseAction.requestFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  })
);
