import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'generateArray',
})
export class GenerateArrayPipe implements PipeTransform {
  transform(value: number | undefined): number[] {
    if (!value || value < 0) {
      return []; // Return an empty array if count is negative
    }

    return Array.from({ length: value }, (_, index) => index);
  }
}
