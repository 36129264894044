import { createAction, props } from '@ngrx/store';
import { TableCreate, TableResponse } from '../../../models/table.model';

export const loadTables = createAction('[Table Page] Load Tables');

export const loadTablesFromHistoryPage = createAction(
  '[History Page] Load Tables'
);

export const loadTablesFromLayout = createAction(
  '[Layout Component] Load Tables'
);

export const createTable = createAction(
  '[Table Page] Create Table',
  props<{ payload: TableCreate }>()
);

export const updateTable = createAction(
  '[Table Page] Update Table',
  props<{ payload: TableCreate; id: number }>()
);

export const deleteTable = createAction(
  '[Table Page] Delete Table',
  props<{ id: number }>()
);

export const tablesLoadedSuccessfully = createAction(
  '[API Call] Tables fetched successfully',
  props<{ result: TableResponse[] }>()
);

export const tablesLoadFailed = createAction(
  '[API Call] Tables fetch failed',
  props<{ error: string }>()
);

export const tableCreatedSuccessfully = createAction(
  '[API Call] Table Created Successfully',
  props<{ result: TableResponse }>()
);

export const tableCreateFailed = createAction(
  '[API Call] Table Create Failed',
  props<{ error: string }>()
);

export const tableUpdatedSuccessfully = createAction(
  '[API Call] Table Updated Successfully',
  props<{ result: TableResponse; id: number }>()
);

export const tableUpdateFailed = createAction(
  '[API Call] Table Update Failed',
  props<{ error: string }>()
);

export const tableDeletedSuccessfully = createAction(
  '[API Call] Table Deleted Successfully',
  props<{ id: number }>()
);

export const tableDeleteFailed = createAction(
  '[API Call] Table Delete Failed',
  props<{ error: string }>()
);
