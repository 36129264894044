import { Component, EventEmitter, Input, Output } from '@angular/core';
import { icons } from '../../../../models/fontawesome';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  readonly Icons = icons;
  @Input() buttonType!:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'link'
    | 'discard';
  @Input() buttonSize: 'small' | 'medium' | 'large' = 'medium';
  @Input() buttonDisabled = false;
  @Input() buttonLoading = false;

  @Output() buttonClick = new EventEmitter<void>();

  get buttonTypeClass(): string {
    switch (this.buttonType) {
      case 'primary':
        return 'bg-primary-dark text-white';
      case 'discard':
        return 'bg-transparent text-primary border border-primary';
      case 'secondary':
        return 'bg-gray-500 text-white';
      case 'tertiary':
        return 'bg-gray-200 text-black';
      case 'success':
        return 'bg-green-500 text-white';
      case 'warning':
        return 'bg-yellow-500 text-black';
      case 'danger':
        return 'bg-red-500 text-white';
      case 'info':
        return 'bg-teal-500 text-white';
      case 'link':
        return 'text-blue-500 underline';
      default:
        return '';
    }
  }

  get buttonSizeClass(): string {
    switch (this.buttonSize) {
      case 'small':
        return 'py-1.5 px-2.5 text-sm';
      case 'medium':
        return 'py-2 px-4 text-base';
      case 'large':
        return 'py-3 px-6 text-lg';
      default:
        return '';
    }
  }

  handleClick() {
    if (!this.buttonDisabled && !this.buttonLoading) {
      this.buttonClick.emit();
    }
  }
}
