<ng-container *ngIf="!loading; else loadingTemplate">
  <div class="w-full rounded bg-zinc-100 shadow relative">
    <div class="w-full min-h-3 p-3 flex flex-col font-inter">
      <div class="flex justify-between items-center">
        <div class="text-base font-medium">{{ title }}</div>
      </div>
      <div class="py-3">
        <ng-content></ng-content>
      </div>
      <div class="flex justify-end items-center border-t pt-3">
        <fa-icon
          class="mr-5"
          [icon]="deleteIcon"
          (click)="delete.emit()"></fa-icon>
        <fa-icon [icon]="editIcon" (click)="edit.emit()"></fa-icon>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="w-full rounded bg-zinc-200 animate-pulse shadow relative">
    <div class="w-full min-h-3 p-3 flex flex-col font-inter"></div>
  </div>
</ng-template>
