import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const allowedRoles = route.data['roles'];
  if (authService.user && allowedRoles?.includes(authService.user.role)) {
    return true;
  }
  return router.navigate(['/not-found']);
};

export const roleGuardForChild: CanActivateChildFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const allowedRoles = route.data['roles'];
  if (authService.user && allowedRoles?.includes(authService.user.role)) {
    return true;
  }
  return router.navigate(['/not-found']);
};
