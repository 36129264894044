import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { IconNames, icons } from '../../../../models/fontawesome';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  Icons = icons;
  searchQuery$: Subject<void> = new Subject<void>();
  searchValue: string | undefined;
  @Input() form!: FormGroup;
  @Input() control!: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() formStyleType: 'solid' | 'simple' = 'simple';
  @Input() prefixIcon?: IconNames;
  @Input() suffixIcon?: IconNames;

  @Output() search = new EventEmitter<string>();

  ngOnInit(): void {
    this.searchQuery$.pipe(debounceTime(1000)).subscribe(() => {
      this.search.emit(this.searchValue);
    });
  }

  handleSearchFn(): void {
    this.searchQuery$.next();
  }
}
