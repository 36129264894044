import { Injectable } from '@angular/core';
import { BaseApiCall } from '../core/services/BaseApiCall';
import { TableCreate, TableResponse } from '../models/table.model';

@Injectable({
  providedIn: 'root',
})
export class TableClient extends BaseApiCall<TableResponse, TableCreate> {
  constructor() {
    super('tables');
  }
}
