<ng-container *ngIf="label; else noLabel">
  <label class="block">
    <span class="text-gray-700">{{ label }}</span>
    <div class="w-full relative">
      <ng-container *ngIf="prefixIcon">
        <fa-icon
          [icon]="Icons[prefixIcon]"
          class="font-normal text-xs md:text-sm text-custom-gray-500 absolute left-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>
      <input
        type="search"
        [(ngModel)]="searchValue"
        (input)="handleSearchFn()"
        [ngClass]="{
          'bg-gray-100 border-transparent': formStyleType === 'solid',
          'border-primary': formStyleType === 'simple',
          'pl-7': prefixIcon,
          'pr-7': suffixIcon
        }"
        class="mt-1 block w-full text-sm rounded-md focus:border-primary focus:ring-primary focus:bg-white"
        [placeholder]="placeholder" />
      <ng-container *ngIf="suffixIcon">
        <fa-icon
          [icon]="Icons[suffixIcon]"
          class="font-normal text-xs md:text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
      </ng-container>
    </div>
  </label>
</ng-container>

<ng-template #noLabel>
  <div class="w-full relative">
    <ng-container *ngIf="prefixIcon">
      <fa-icon
        [icon]="Icons[prefixIcon]"
        class="font-normal text-xs md:text-sm text-custom-gray-500 absolute left-2 top-1/2 -translate-y-1/2"></fa-icon>
    </ng-container>
    <input
      type="search"
      [(ngModel)]="searchValue"
      (input)="handleSearchFn()"
      [ngClass]="{
        'bg-gray-100 border-transparent': formStyleType === 'solid',
        'border-primary': formStyleType === 'simple',
        'pl-7': prefixIcon,
        'pr-7': suffixIcon
      }"
      class="block w-full text-sm rounded-md focus:border-primary focus:ring-primary focus:bg-white"
      [placeholder]="placeholder" />
    <ng-container *ngIf="suffixIcon">
      <fa-icon
        [icon]="Icons[suffixIcon]"
        class="font-normal text-xs md:text-sm text-custom-gray-500 absolute right-2 top-1/2 -translate-y-1/2"></fa-icon>
    </ng-container>
  </div>
</ng-template>
