import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { icons } from '../../../../models/fontawesome';
import { DropdownItem } from '../../../../models/types';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  readonly Icons = icons;
  @Input() items: DropdownItem[] = [];
  @Input() form!: FormGroup;
  @Input() control!: string;
  @Input() formStyleType: 'solid' | 'simple' = 'simple';
  @Input() label: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() className = '';
  @Input() errorText?: string;

  isOptionsOpen = false;
  selectedItems: Map<any, DropdownItem> = new Map();

  get selectedValues(): DropdownItem[] {
    return Array.from(this.selectedItems.values());
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    const defaultValues: any[] = this.form?.get(this.control)?.value;
    if (defaultValues?.length) {
      defaultValues.forEach(entity => {
        this.selectedItems.set(
          entity,
          this.items.find(item => item.value === entity) ?? {
            text: entity,
            value: entity,
          }
        );
      });
    }
  }

  toggleDropdown(): void {
    this.isOptionsOpen = !this.isOptionsOpen;
  }

  toggleSelectedItems(item: DropdownItem): void {
    if (this.selectedItems.has(item.value)) {
      this.selectedItems.delete(item.value);
    } else {
      this.selectedItems.set(item.value, item);
    }
    this.form
      .get(this.control)
      ?.patchValue(Array.from(this.selectedItems.keys()));

    this.cd.detectChanges();
  }
}
