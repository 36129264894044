<div
  [ngClass]="{
    'bg-primary-light': selectedDate,
    'bg-custom-gray-300': !selectedDate
  }"
  [id]="'main-wrapper-' + calendarNumber"
  class="relative flex items-center justify-center gap-x-1 rounded-full px-2.5 py-1">
  <span class="text-sm font-normal text-custom-gray-500 mr-1">
    {{ title }}:
  </span>
  <p
    class="cursor-pointer whitespace-nowrap text-sm font-medium text-custom-gray-600"
    *ngIf="!selectedDate">
    {{ placeholder }}
  </p>
  <p
    class="cursor-pointer flex items-center whitespace-nowrap text-sm text-primary-dark font-medium"
    *ngIf="selectedDate">
    {{ selectedDate | date: 'dd MMM, yyyy' : undefined : 'uz' }}
  </p>

  <div
    *ngIf="selectedDate"
    (click)="$event.stopImmediatePropagation(); handleRemove()"
    class="flex items-center justify-center py-0 px-1 cursor-pointer rounded-full text-[10px] bg-primary text-primary-light transition">
    <fa-icon [icon]="Icons.xmark"></fa-icon>
  </div>
  <!--  <app-calendar-->
  <!--    *ngIf="showCalendar"-->
  <!--    (click)="$event.stopImmediatePropagation()"-->
  <!--    [id]="'calendarComponent-' + calendarNumber"-->
  <!--    [ngClass]="{-->
  <!--      'opacity-100 visible': showCalendar,-->
  <!--      'opacity-0 invisible': !showCalendar-->
  <!--    }"-->
  <!--    [enableShortNames]="enableShortName"-->
  <!--    [initialDate]="selectedDate"-->
  <!--    (dateSelected)="handleSelectedDate($event)"-->
  <!--    class="bg-white border rounded-lg shadow-md py-3 px-4 transition-opacity inline-block absolute top-8 z-10"></app-calendar>-->
</div>
