import { Injectable } from '@angular/core';
import { User, UserCreate } from '../models/user.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserClient {
  private requestEndpoint = environment.baseApiUrl + '/users';
  constructor(private http: HttpClient) {}

  changePassword(payload: {
    oldPassword: string;
    newPassword: string;
  }): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      `${this.requestEndpoint}/change-password`,
      payload
    );
  }

  usernameAvailability(username: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.requestEndpoint}/availability/${username}`
    );
  }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(this.requestEndpoint);
  }

  get(userId: number): Observable<User> {
    return this.http.get<User>(`${this.requestEndpoint}/${userId}`);
  }

  create(payload: UserCreate): Observable<User> {
    return this.http.post<User>(this.requestEndpoint, payload);
  }

  update(
    userId: number,
    payload: Omit<UserCreate, 'password'>
  ): Observable<User> {
    return this.http.put<User>(`${this.requestEndpoint}/${userId}`, payload);
  }

  delete(userId: number): Observable<void> {
    return this.http.delete<void>(`${this.requestEndpoint}/${userId}`);
  }
}
