import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  transform(value: string | null): string {
    return value && value.replaceAll(/ /g, '').length
      ? value
      : 'assets/images/placeholder.jpeg';
  }
}
