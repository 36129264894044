import { createReducer, on } from '@ngrx/store';
import { User } from '../../../models/user.model';
import { CoreAction } from '../../actions/index.action';

export const featureKey = 'core';
export interface State {
  isLoading: boolean;
  errorMessage?: string;
  authorizedUser?: User;
}
export const initialState: State = {
  isLoading: false,
  authorizedUser: localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user')!)
    : undefined,
  errorMessage: undefined,
};

export const reducer = createReducer<State>(
  initialState,
  on(CoreAction.loading, (state, action) => {
    return {
      ...state,
      isLoading: action.loading,
    };
  }),
  on(CoreAction.loginSuccess, (state, action) => {
    return {
      ...state,
      authorizedUser: action.user,
      isLoading: false,
    };
  }),
  on(CoreAction.loginFail, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
      isLoading: false,
    };
  })
);
