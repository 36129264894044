import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ComponentClient } from '../../../_clients/component.client';
import { CoreAction, WarehouseAction } from '../../actions/index.action';
import { catchError, concatMap, map, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ComponentsEffect {
  constructor(
    private componentClient: ComponentClient,
    private actions$: Actions
  ) {}

  loadComponents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseAction.loadComponents),
      concatMap(() => [CoreAction.loading({ loading: true })]),
      concatMap(() =>
        this.componentClient.getAll().pipe(
          switchMap(response => [
            CoreAction.loading({ loading: false }),
            WarehouseAction.loadComponentSuccess({
              records: response,
            }),
          ]),
          catchError((err: HttpErrorResponse) =>
            of(
              CoreAction.loading({ loading: false }),
              WarehouseAction.requestFailed({ error: err.error.message })
            )
          )
        )
      )
    );
  });

  createComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseAction.createComponent),
      concatMap(action =>
        of(CoreAction.loading({ loading: true })).pipe(
          map(() => action) // Pass along the action
        )
      ),
      concatMap(action =>
        this.componentClient.create(action.payload).pipe(
          switchMap(response => [
            CoreAction.loading({ loading: false }),
            WarehouseAction.createComponentSuccess({ record: response }),
          ]),
          catchError((err: HttpErrorResponse) =>
            of(
              CoreAction.loading({ loading: false }),
              WarehouseAction.requestFailed({ error: err.error.message })
            )
          )
        )
      )
    );
  });

  updateComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseAction.updateComponent),
      concatMap(action =>
        of(CoreAction.loading({ loading: true })).pipe(
          map(() => action) // Pass along the action
        )
      ),
      concatMap(action =>
        this.componentClient.update(action.id, action.payload).pipe(
          switchMap(response => [
            CoreAction.loading({ loading: false }),
            WarehouseAction.updateComponentSuccess({ record: response }),
          ]),
          catchError((err: HttpErrorResponse) =>
            of(
              CoreAction.loading({ loading: false }),
              WarehouseAction.requestFailed({ error: err.error.message })
            )
          )
        )
      )
    );
  });

  deleteComponent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WarehouseAction.deleteComponent),
      concatMap(action =>
        of(CoreAction.loading({ loading: true })).pipe(
          map(() => action) // Pass along the action
        )
      ),
      concatMap(action =>
        this.componentClient.delete(action.id).pipe(
          switchMap(response => [
            CoreAction.loading({ loading: false }),
            WarehouseAction.deleteComponentSuccess({ id: action.id }),
          ]),
          catchError((err: HttpErrorResponse) =>
            of(
              CoreAction.loading({ loading: false }),
              WarehouseAction.requestFailed({ error: err.error.message })
            )
          )
        )
      )
    );
  });
}
