import { Order, OrderFilter } from '../../../models/order.model';
import { createReducer, on } from '@ngrx/store';
import { HistoryAction } from '../../actions/index.action';

export const featureKey = 'history';

export interface State {
  orders: Order[];
  errorMessage?: string;
  pagination: {
    page: number;
    size: number;
    totalPages: number;
    totalElements?: number;
  };
  filter: OrderFilter;
}
export const initialState: State = {
  orders: [],
  errorMessage: undefined,
  pagination: {
    page: 1,
    size: 10,
    totalPages: 1,
  },
  filter: {},
};

export const reducer = createReducer<State>(
  initialState,
  on(HistoryAction.ordersLoadedSuccessfully, (state, action): State => {
    return {
      ...state,
      filter: action.filter,
      orders: action.result.content,
      pagination: {
        ...state.pagination,
        totalElements: action.result.totalElements,
        totalPages: action.result.totalPages,
      },
    };
  }),
  on(HistoryAction.loadOrders, (state, action): State => {
    return {
      ...state,
      filter: action.filter,
      pagination: {
        page: 1,
        size: 10,
        totalPages: 1,
      },
    };
  }),
  on(HistoryAction.changePagination, (state, action): State => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page: action.page,
        size: action.size,
      },
    };
  }),
  on(HistoryAction.paginateOrdersLoadedSuccessfully, (state, action): State => {
    return {
      ...state,
      orders: action.result.content,
      pagination: {
        ...state.pagination,
        totalElements: action.result.totalElements,
        totalPages: action.result.totalPages,
      },
    };
  }),
  on(HistoryAction.nextInfiniteScrollPage, (state): State => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        page:
          state.pagination.page < state.pagination.totalPages
            ? state.pagination.page + 1
            : state.pagination.page,
      },
    };
  }),
  on(
    HistoryAction.nextInfiniteScrollPageLoadedSuccess,
    (state, action): State => {
      return {
        ...state,
        orders: [...state.orders, ...action.result.content],
        pagination: {
          ...state.pagination,
          totalElements: action.result.totalElements,
          totalPages: action.result.totalPages,
        },
      };
    }
  ),
  on(HistoryAction.orderLoadFailed, (state, action): State => {
    return {
      ...state,
      errorMessage: action.error,
    };
  })
);
