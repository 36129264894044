import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageClient {
  private readonly apiUrl = environment.baseApiUrl + '/media/image-upload';
  constructor(private http: HttpClient) {}

  upload(file: File): Observable<{ url: string }> {
    const formData = new FormData();
    formData.set('file', file);
    return this.http.post<{ url: string }>(this.apiUrl, formData);
  }
}
