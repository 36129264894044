import { TableResponse } from '../../../models/table.model';
import { createReducer, on } from '@ngrx/store';
import { TableAction } from '../../actions/index.action';

export const featureKey = 'tables';

export interface State {
  records: TableResponse[];
  errorMessage?: string;
}

export const initialState: State = {
  records: [],
  errorMessage: undefined,
};

export const reducer = createReducer<State>(
  initialState,
  on(TableAction.tablesLoadedSuccessfully, (state, action) => {
    return {
      ...state,
      records: action.result,
    };
  }),
  on(TableAction.tablesLoadFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  }),
  on(TableAction.tableCreatedSuccessfully, (state, action) => {
    return {
      ...state,
      records: [...state.records, action.result],
    };
  }),
  on(TableAction.tableCreateFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  }),
  on(TableAction.tableUpdatedSuccessfully, (state, action) => {
    return {
      ...state,
      records: state.records.map(table => {
        if (table.id === action.id) {
          return action.result;
        }
        return table;
      }),
    };
  }),
  on(TableAction.tableUpdateFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  }),
  on(TableAction.tableDeletedSuccessfully, (state, action) => {
    return {
      ...state,
      records: state.records.filter(table => table.id !== action.id),
    };
  }),
  on(TableAction.tableDeleteFailed, (state, action) => {
    return {
      ...state,
      errorMessage: action.error,
    };
  })
);
