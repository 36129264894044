import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { User } from '../../../models/user.model';
import { JwtService } from '../jwt/jwt.service';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { CommonService } from '../../../_services/common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get token(): string | undefined {
    if (this._token) return this._token;
    const storageData = this._localStorage.get<string>('token');
    if (storageData) {
      this.token = storageData;
      return storageData;
    }
    return undefined;
  }

  set token(value: string) {
    this._localStorage.set('token', value);
    this._token = value;
  }

  get user(): User | undefined {
    if (this._user) return this._user;
    const storageData = this._localStorage.get<User>('user');
    if (storageData) {
      this._user = storageData;
      return storageData;
    } else {
      this._localStorage.clear();
    }
    return undefined;
  }

  set user(value: User) {
    this._user = value;
    this._localStorage.set('user', value);
  }

  private _token: string | undefined;
  private _user: User | undefined;
  constructor(
    private jwtService: JwtService,
    private http: HttpClient,
    private router: Router,
    private _localStorage: LocalStorageService,
    private common: CommonService
  ) {}

  isTokenExpired(from?: string): boolean {
    if (this.token) {
      const tokenPayload = this.jwtService.decode(this.token);
      const diff = differenceInMilliseconds(
        tokenPayload.exp! * 1000,
        new Date()
      );
      return diff < 0;
    }
    return true;
  }

  login(payload: {
    username: string;
    password: string;
  }): Observable<LoginResponse> {
    return this.http.post<{ token: string; user: User }>(
      environment.baseApiUrl + '/login',
      payload
    );
  }

  logout(): void {
    this._localStorage.clear();
    this._token = undefined;
    this._user = undefined;
    this.common.clearAppState();
    this.router.navigate(['/auth/login']);
  }
}

export type LoginResponse = {
  token: string;
  user: User;
};
